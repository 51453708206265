@charset "utf-8";

@import '..\/..\/Core.scss';

@media (min-width: 576px) {}

@media (min-width: 768px) {
}

@media (min-width: 993px) {}

@media (min-width: 1200px) {}

@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {

}

@media (max-width: 991.98px) {

}

@media (max-width: 1199.98px) {
}

@media (min-width: 320px) and (max-width: 575.98px) {
div .col-icon-bg-cntr {
  margin-bottom: 20px;
}
div .col-icon-bg-cntr:not(.no-margin) {
    margin-bottom: 20px;
}

p{
  font-size: 14px;
  color: #333333;
  line-height: 1.5rem;
  padding: 0;
  margin: 0;
  letter-spacing: .5px;
}

  .hero-image {

    background-size: cover;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
  }


  .hero-image-small {
    background-size: 350%;    
  }
  .img-container img.mt{ margin-top: 30px;}
  .navbar-brand {
    padding-left: 15px;
  }
  .navbar-brand img{
    margin-top: 0;
  }
  
  .section-first{
    padding: 30px 0px 20px 0px;
  }
  .section{
    padding: 20px 0px;
  }
  .section-last{
    padding: 20px 0px 30px 0px;
  }
  

  .section-large-first{
    padding: 30px 0px 15px 0px;
  }
  .section-large{
    padding: 30px 0px;
  }
  .section-large-last{
    padding: 15px 0px 30px 0px;
  }
  
  
  .section-larger-first{
    padding: 30px 0px 15px 0px;
  }
  .section-larger{
    padding: 30px 0px;
  }
  .section-larger-last{
    padding: 20px 0px 30px 0px;
  }
 
  .btn {
    font-size: 12px;
    padding: 10px 15px !important;
    margin-top: 20px;    
  }
  .input-group-append .btn{
    margin: 5px 10px 5px 0px !important;
    font-size: 14px;
  }
  .hero-text {
    text-align: center;
    padding: 0px 0px 20px 0px;
  }
  .hero-text h1 {
    font-weight: 400;
    padding-bottom: 5px;
  }
  .hero-text-inner {
    width: 100%;
    padding: 50px 0px;
    margin-top: 0px;
  }
  .hero-text-inner .sub-heading-1 {
    font-size: 16px;
  }
  .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border: 0px;
    padding-right: 15px;
    position: absolute;
    right: 0;
    top: 4px;
  }  
  .numbers {
    padding: 30px;
    margin-bottom: 20px;
  }
  .col-lg-4:nth-last-child(1) .numbers{
    margin: 0px 0px 0px 0px;
  }
  .numbers h2 {
    color: #f23750;
    font-weight: 600;
    font-size: 1.5rem;
  }
  .numbers h2 span{
    font-size: 18px;
    font-weight: 400;
  }

  .bg-color-10{
    background-color: #DCDCDC;
    padding-left: 0px;
    padding-right: 0px;
  }
  
  .bullet-points ul li {
    font-size: 15px;
  }
  .slider-text-bg {
    width: 100%;
    height: 200px;
    padding: 0px;
    position: relative;
  }
 

  .title-center{
    text-align: center;
    padding-bottom: 15px;
  }
  .title-center h2 {
    padding-bottom: 10px;
  }
  .bg-color-dark .sub-heading-1, .bg-color-grad .sub-heading-1 {
    color: #2c1a26;
    font-size: 18px;
  }

  .text-container-right {
    padding-left: 15px;
    padding-right: 15px;
    
  }

  .sub-heading-1, .sub-heading-2{
    color: #B22727;
    font-size: 18px;
  }
  .sub-heading-1 {
    margin: 0;
    padding: 0px;
    font-weight: 600;
    line-height: 20px;
    display: block;
  }
  .bg-color-dark .sub-heading-2, .bg-color-grad .sub-heading-2 {
    margin: 10px 0px 10px 0px ;
  }
  .sub-heading-2 {
    margin: 10px 0px 10px 0px ;
  }
  .navbar-light .navbar-nav .nav-link {
    font-size: 14px;
    color: #222222;
    transition: all .3s ease-in-out;
    padding: 20px 15px;
  }
  .bg-dark {
    padding: 0px 0px;
    display: block;
  }
  .bg-dark.scrolled .nav-link{
    color: #111111;
    font-size: 14px;
  }
  
  .dropdown-menu a{
    font-size: 14px;
  }
  .offers ul li p, .offers a{
    font-size: 13px;
  }
  .client{
    text-align: center;
    margin-bottom: 30px;
    justify-content: center;
  }
  .client:nth-last-child(1){
    margin-bottom: 0px;
  }
  .client:nth-last-child(2){
    margin-bottom: 0px;
  }

  .bg-color-dark {
    background: -moz-linear-gradient(-45deg, #e86f4b 0%, #f93156 100%);
    background: -webkit-linear-gradient(-45deg, #e86f4b 0%, #f93156 100%);
    background-size: 150%;
  }

  footer{
    padding: 30px 0px 0px 0px
  }
  footer .copyright {
    margin-top: 30px;
  }  
  footer p{
      font-size: 14px;
  }
  
  footer li, footer a{
    font-size: 14px;
  }
  .footer-sec:nth-last-child(1){
    margin-bottom: 0px;
  }  
  
  .copyright-social{
    float: left;
    padding-top: 20px;
  }
  .footer-sec{
    margin-bottom: 20px;
  }
  .copyright-social ul li{
    padding: 0px 10px 0px 0px;
  }

  .pro-border::after {
    content: '';
    display: none;
  }

  .product {
    text-align: center;
    border-bottom: 1px solid #cccccc;
    margin-bottom: 30px;
    padding-bottom: 30px;
    
  }
  .pro-border:nth-last-child(1) .product {
    border-bottom: none;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  .list-icon span::before {
    font-size: 40px;
  }
  
  .icon-cntr span::before {
    font-size: 60px;
  }

  .point-list .list-text{
    margin-left: 80px;
  }
  .point-list ul li{
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #cccccc;
  }
  
  .point-list ul li:nth-last-child(1){
    padding-bottom: 0px;
    margin-bottom: 50px;
    border-bottom: none;
  }
  
  .point-list ul li:nth-last-child(1) {
    padding-bottom: 0px;
    margin-bottom: 0px;
    border-bottom: none;
  }
  .point-list ul li.last {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #cccccc;
  }
  .feedback-list {
    background-size: contain;
    padding: 30px 30px 0px 30px;
  
  }
  .feedback-content-left {
    padding: 90px 0px;
    position: relative;
  }
  .feedback p{
    position: relative;
    top: 20px;
  }
  .feedback-pic-left {
    width: 100px;
    height: 100px;
    border: 5px solid #ffffff;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    left: 0px;
    top: -70px;
  }
  .feedback-text-left {
    padding: 0px 0px 0px 0px;
    position: relative;
    top: -40px;
  }
  .feedback > img {
    position: absolute;
    top: -7%;
    width: 40%;
  }
  .team-list .team:nth-child(1){
    margin-top: 0px;
  }
  .team{
    margin-top: 20px;
  }
  .team img {
    width: 100%;
    height: auto;
  }

  .img-container-step img {
  width: auto;
  max-height: 160px;
  }
  .reason-sec-back {
  height: 310px;
  background-size: 120%;
  background-attachment: fixed;
  }
  .product h6 {
  font-size: 15px;
  font-weight: 600;
  padding: 0;
  }
  .pro-icons span::before {
  font-size: 40px;
  }
  .icon-left span::before {
    font-size: 40px;
    
  }
  .product .pro-icons {
  padding-bottom: 15px;
  }
  .col-icon-cntr-claim {
  width: 100%;
  background-color: "";
  padding: 20px;
  margin-top: 20px;
  }
  
  .col-icon-cntr-claim:hover {
  padding: 20px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, .2);
  }
  .no-of-package .package{
    margin-top: 30px;
  }
  .insure-text {
    padding: 15px !important;
    margin-bottom: 30px;
    margin-top: 40px;
    position: relative;
  }
  .insure-text .highlight {
    margin: 0px 0px;
  }  
  .insure-text h6{
    padding: 10px 0px;
  }
  .insure-text img {
  display: none;
  }


  .insure-form form .form-group {
    margin-bottom: 10px;
  }
  .insure-form h5 {
    padding-top: 0px;
    padding-bottom: 10px;
  }
  .insure-form .form-group legend {
    display: block;
    padding-right: 20px;
  }
  .insure-form h6 {
    padding-top: 30px;
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
  }
  .plan{
    margin-top: 30px;
  }
  .offers{
    margin: 30px 0px;
  }
  .plan-selected{
    margin-top: 20px;
  }
  .car-info {
    padding-left: 20px;
    border-left: 2px solid #f23750;
  }
  .send-quotes{
    margin-top: 0px;
    padding: 30px;
    border-radius: 20px;
  }
  .highlight {
    margin: 0px -15px;
  }  
  .highlight h5 {
    line-height: 30px;
    padding-bottom: 0px;
  }  
  .highlight-top h5{
    line-height: 30px;
    padding-bottom: 20px;
}
  .high-img img {
    display: none;
   
  }
  .no-of-package .package {
    margin-top: 0px;
    margin-bottom: 0px;
  }
    .package {
        background: #e8e8e8 url(https://cdn.lonestarmga.com/img/Product-Card.png) no-repeat center 0px;
        background-size: 100%;
        padding: 30px;
        margin-bottom: 30px;
        border-radius: 25px;
        transition: all .3s ease-in-out;
    }
        .package:hover {
            background: #ffffff url(https://cdn.lonestarmga.com/img/Product-Card.png) no-repeat center 0px;
            background-size: 110%;
            box-shadow: 0px 15px 25px rgba(0, 0, 0, .3);
        }
  .package h5 {
   font-size: 1.25rem;
    font-weight: 600;
    padding-top: 20px;
    padding-bottom: 100px;
    text-align: center;
  }
  .duration h4 {
  font-size: 50px;
  line-height: 25px;
  }
  .duration h4 span {
  font-size: 15px;
  }
  .package ul li {
  font-size: 14px;   
  }

    .package ul li::after {
        content: '';
        display: block;
        background: url(https://cdn.lonestarmga.com/img/Product-Card.png) no-repeat center left;
        background-size: contain;
        width: 16px;
        height: 16px;
        position: absolute;
        right: 0;
        top: 25px;
    }

  .col-icon-cntr-claim .icon-cntr-claim {
    padding-bottom: 10px;
  }
  .icon-left {
    text-align: left;
    padding-bottom: 15px;
  }
  .reason-sec {
    border-radius: 20px;
    padding: 50px 25px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, .1);
    position: relative;
    margin-top: -200px;
    z-index: 1;
  }
  .bike-works{
    margin-top: 30px;
  }
  .factors{
    margin-top: 30px;
  }
  
  .owl-theme .owl-nav.disabled + .owl-dots {
    bottom: 20px;
    left: 0;
    right: 0; 
  }

  .videos > .owl-dots{
    margin-top: 0px;
    margin-left: 3px;
    position: absolute;
    bottom: -5px !important;
    left: 0;
    right: 0;
  }

  .last .col-icon-left-sml{
    padding-bottom: 0;
  }
  .icon-left-sml{
    float: left;
    width: 25px;
    height: 25px;
  }
  .icon-left-sml img{
    width: 100%;
    height: auto;
  }
  .col-img-cntr {
    margin-top: 0px;
    margin-bottom: 50px;
  }
  .text-left-sml {
    margin-left: 40px;
  }
  .col-sm-12:nth-last-child(1) .col-img-cntr {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .img-cntr-claim img {
    max-width: 60%;
    margin: 0px auto auto auto;
  }
}


@media (max-width: 320px) {

  .point-list .list-text {
    margin-left: 60px;
  }
  .bg-color-dark .sub-heading-2, .bg-color-grad .sub-heading-2 {
    line-height: 25px;   
  }
  .text-container-right {
    padding-left: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .btn{
    margin-top: 20px;
  }
  .input-group-append .btn{
    margin: 15px 0px;
  }
  .package h5 {
    font-size: 1.25rem;
    font-weight: 600;
    padding-top: 0px;
    padding-bottom: 60px;
    text-align: center;
  }
  .bg-color-dark {
    background: -moz-linear-gradient(-45deg, #e86f4b 0%, #f93156 100%);
    background: -webkit-linear-gradient(-45deg, #e86f4b 0%, #f93156 100%);
    background-size: 150%;
  }
  .owl-theme .owl-nav.disabled + .owl-dots {
    bottom: 40px;
    left: 0;
    right: 0; 
  }
  .feedback-content-left {
    padding: 90px 0px 70px 0px;
    position: relative;
  }
}

@media (min-width: 320px) and (max-width: 379.99px) {
  .package h5 {
    font-size: 1.25rem;
    font-weight: 600;
    padding-top: 0px;
    padding-bottom: 70px;
    text-align: center;
  }
  .feedback-list {
    background-size: contain;
    padding: 0px 30px 0px 30px;
    position: relative;
    top: 30px;
  }

}

@media (min-width: 380px) and (max-width: 420px) {
  .package h5 {
    font-size: 1.25rem;
    font-weight: 600;
    padding-top: 0px;
    padding-bottom: 80px;
    text-align: center;
  }
  .slider-text-bg {
    width: 100%;
    height: 250px;
    padding: 0px;
    position: relative;
  }
 

  .feedback-list {
    background-size: contain;
    padding: 0px 30px 0px 30px;
    position: relative;
    top: 30px;
  }
  .feedback-content-left {
    padding: 90px 0px 60px 0px;
    position: relative;
    top: -20px;
    margin: 30px 0px 60px 0px;
  }
  .owl-theme .owl-nav.disabled + .owl-dots {
    bottom: 30px;
    left: 0;
    right: 0;
  }
}
@media (min-width: 421px) and (max-width: 575.99px) {
  .slider-text-bg {
    width: 100%;
    height: 350px;
    padding: 0px;
    position: relative;
  }


  .feedback-list {
    background-size: contain;
    padding: 0px 30px 0px 30px;
    position: relative;
    top: 0px;
  }
  .feedback-pic-left {
    position: relative;
    left: 0px;
    top: -40px;
  }
  .feedback-text-left {
    padding: 0px 0px 0px 0px;
    position: relative;
    top: -30px;
  }
  .feedback-content-left {
    padding: 90px 0px 80px 0px;
    position: relative;
  }
  .package h5 {
    font-size: 1.25rem;
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 90px;
    text-align: center;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
div .col-icon-bg-cntr {
    margin-bottom: 30px;
}
div .col-icon-bg-cntr:not(.no-margin) {
    margin-bottom: 30px;
}

p{
  font-size: 14px;
  color: #333333;
  line-height: 1.5rem;
  padding: 0;
  margin: 0;
  letter-spacing: .5px;
}


  .hero-image-small {
    background-size: 200%;    
  }
  .img-container img.mt{ margin-top: 30px;}
  .navbar-brand {
    padding-left: 15px;
  }
  .navbar-brand img{
    margin-top: 0;
  }
  
  .section-first{
    padding: 60px 0px 25px 0px;
  }
  .section{
    padding: 25px 0px;
  }
  .section-last{
    padding: 25px 0px 60px 0px;
  }
  
  
  .section-large-first{
    padding: 40px 0px 20px 0px;
  }
  .section-large{
    padding: 40px 0px;
  }
  .section-large-last{
    padding: 20px 0px 40px 0px;
  }
  
  
  .section-larger-first{
    padding: 60px 0px 25px 0px;
  }
  .section-larger{
    padding: 60px 0px;
  }
  .section-larger-last{
    padding: 25px 0px 60px 0px;
  }
  
  .hero-text {
    text-align: center;
    padding: 0px 0px 40px 0px;
  }
  .hero-text h1 {
    font-weight: 300;
    padding-bottom: 10px;
  }
  .hero-text-inner {
    width: 100%;
    padding: 50px 0px;
    margin-top: 20px;
  }
  .hero-text-inner .sub-heading-1 {
    font-size: 16px;
  }
  .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border:0px;
    padding-right: 15px;
    position: absolute;
    right: 0;
    top: 4px;
  }  
  .numbers {
    padding: 0px 0px 30px 0px;
  }
  .numbers h2 {
    color: #f23750;
    font-weight: 600;
    font-size: 1.75rem;
  }
  .numbers h2 span{
    font-size: 19px;
    font-weight: 400;
  }
  .numbers {
    padding: 30px;
    margin-bottom: 20px;
  }
  .col-lg-4:nth-last-child(1) .numbers{
    margin: 0px 0px 0px 0px;
  }
  
  .bg-color-10{
    background-color: #DCDCDC;
    padding-left: 40px;
    padding-right: 40px;
  }
  
  .bullet-points ul li {
    font-size: 15px;
  }
  
  .slider-text-bg {
    width: 100%;
    height: 300px;
    padding: 0px;
    position: relative;
  }

  .title-center{
    text-align: center;
    padding-bottom: 20px;
  }
  .title-center h2 {
    padding-bottom: 10px;
  }

  .bg-color-dark .sub-heading-1, .bg-color-grad .sub-heading-1 {
    color: #2c1a26;
    font-size: 18px;
  }
  .highlight-top h5{
    line-height: 30px;
    padding-bottom: 20px;
}
  .sub-heading-1, .sub-heading-2{
    color: #B22727;
    font-size: 18px;
  }
  .sub-heading-1 {
    margin: 0;
    padding: 0px;
    font-weight: 600;
    line-height: 20px;
    display: block;
  }
  .navbar-light .navbar-nav .nav-link {
    font-size: 14px;
    color: #222222;
    transition: all .3s ease-in-out;
    padding: 20px 15px;
  }
  .bg-dark {
    padding: 0px 0px;
    display: block;
    margin: 0px 15px;
  }
  .bg-dark.scrolled .nav-link{
    color: #111111;
    font-size: 14px;
  }
  
  .dropdown-menu a{
    font-size: 14px;
  }
  .plan{
    margin-top: 30px;
  }
  .offers {
    margin: 30px 0px;
  }
  .offers ul li p, .offers a{
    font-size: 13px;
  }

  .text-container-right {
    padding-left: 15px;
    padding-right: 15px;
  }
  .client{
    text-align: center;
    justify-content: center;
  }
  .client:nth-child(1){
    margin-bottom: 30px;
  }
  footer{
    padding: 40px 0px 0px 0px
  }
  footer .copyright {
    margin-top: 40px;
  }
  .footer-sec{
    margin-bottom: 20px;
  }
  .footer-sec:nth-last-child(1){
    margin-bottom: 0px;
  }  
  .footer-sec:nth-last-child(2){
    margin-bottom: 0px;
    
  }
  footer p{
      font-size: 14px;
  }
  
  footer li, footer a{
    font-size: 14px;
  }
  .copyright-social{
    float: left;
    padding-top: 20px;
  }
  
  .copyright-social ul li{
    padding: 0px 10px 0px 0px;
  }

  .pro-border::after {
    content: '';
    display: none;
  }

  .product {
    text-align: center;
    border-bottom: 1px solid #cccccc;
    margin-bottom: 30px;
    padding-bottom: 30px;
  }
  .pro-border:nth-last-child(1) .product {
    border-bottom: none;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  .list-icon span::before {
    font-size: 40px;
  }
  
  .icon-cntr span::before {
    font-size: 60px;
  }
  .point-list{
    padding: 0px;
  }
  .point-list .list-text{
    margin-left: 80px;
  }
  .point-list ul li{
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #cccccc;
  }
  
  .point-list ul li:nth-last-child(1){
    padding-bottom: 0px;
    margin-bottom: 50px;
    border-bottom: none;
  }
  
  .point-list ul li:nth-last-child(1) {
    padding-bottom: 0px;
    margin-bottom: 0px;
    border-bottom: none;
  }
  .point-list ul li.last {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #cccccc;
  }
  .feedback-list {
    background-size: contain;
    padding: 30px 30px 0px 30px;
  
  }
  .feedback-content-left {
    padding: 90px 0px;
    position: relative;
  }
  .feedback p{
    position: relative;
    top: 20px;
  }
  .feedback-pic-left {
    width: 100px;
    height: 100px;
    border: 5px solid #ffffff;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    left: 0px;
    top: -70px;
  }
  .feedback-text-left {
    padding: 0px 0px 0px 0px;
    position: relative;
    top: -40px;
  }
  .feedback > img {
    position: absolute;
    top: -10%;
  }
  .team-list .team:nth-child(1){
    margin-bottom: 30px;
  }
  
  .img-container-step img {
  width: auto;
  max-height: 160px;
  }
  .reason-sec-back {
  height: 310px;
  background-size: 120%;
  background-attachment: fixed;
  }
  .product h6 {
  font-size: 15px;
  font-weight: 600;
  padding: 0;
  }
  .pro-icons span::before {
  font-size: 40px;
  }
  .product .pro-icons {
  padding-bottom: 15px;
  }
  .col-icon-cntr-claim {
  width: 100%;
  background-color: #f23750;
  padding: 20px;
  }
  
  .col-icon-cntr-claim:hover {
  padding: 20px;
  background: linear-gradient(0deg, #ffffff 0%, #BE0A22 34.69%, #84040F 100%);

  box-shadow: 0px 10px 20px rgba(0, 0, 0, .2);
  }
  .no-of-package .package{
    margin-top: 30px;
  }
  
  .highlight h5 {
    line-height: 30px;
    padding-bottom: 20px;
  }  
  .high-img img {
    display: block;
    position: absolute;
    top: -35px;
    width: 85%;
  }
  .no-of-package .package {
    margin-top: 0px;
    margin-bottom: 0px;
  }
    
  .package{
    background-size: 100%;
    padding: 30px;
    margin-bottom: 30px;
    border-radius: 15px;
    transition: all .3s ease-in-out;
  }
  .package:hover{
    background-size: 110%;
    box-shadow: 0px 20px 30px rgba(0, 0, 0, .3);
  }
  .package h5 {
    font-size: 1.25rem;
    font-weight: 600;
    padding-top: 20px;
    padding-bottom: 100px;
    text-align: center;
  }
  .duration h4 {
  font-size: 50px;
  line-height: 25px;
  }
  .duration h4 span {
  font-size: 15px;
  }
  .package ul li {
  font-size: 14px;   
  }
  
  .package ul li::after {
  content: '';
  display: block;
  background-size: contain;
  width: 16px;
  height: 16px;
  position: absolute;
  right: 0;
  top: 25px;
  }
  
  .icon-cntr-claim span::before {
    font-size: 48px;
  }
  .reason-sec {
    border-radius: 20px;
    padding: 50px 25px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, .1);
    position: relative;
    margin-top: -180px;
    z-index: 1;
  }
  .bike-works{
    margin-top: 30px;
  }
  .factors{
    margin-top: 30px;
  }
  .owl-theme .owl-nav.disabled + .owl-dots {
    bottom: 0px;
  }
  .last .col-icon-left-sml{
    padding-bottom: 0;
  }
  .col-img-cntr {
    margin-top: 0px;
    margin-bottom: 50px;
  }
  
  .col-sm-12:nth-last-child(1) .col-img-cntr {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .img-cntr-claim img {
    max-width: 70%;
  }
  .img-container-sml img {
    max-width: 100%;
  }
  .insure-text {
    padding: 15px;
  }
  .plan-selected{
    margin-top: 20px;
  }
  .car-info {
    padding-left: 20px;
    border-left: 2px solid #f23750;
  }

}

@media (min-width: 768px) and (max-width: 991.98px) {
  
  p{
    font-size: 14px;
    color: #333333;
    line-height: 1.5rem;
    padding: 0;
    margin: 0;
    letter-spacing: .5px;
}

.hero-image {
	-size: cover;
	padding-top: 100px;

}
.img-container img.mt{ margin-top: 30px;}
.navbar-brand {
  padding-left: 15px;
}
.navbar-brand img{
  margin-top: 0;
}

.section-first{
  padding: 80px 0px 30px 0px;
}
.section{
  padding: 30px 0px;
}
.section-last{
  padding: 30px 0px 80px 0px;
}


.section-large-first{
  padding: 50px 0px 20px 0px;
}
.section-large{
  padding: 50px 0px;
}
.section-large-last{
  padding: 20px 0px 50px 0px;
}


.section-larger-first{
  padding: 80px 0px 30px 0px;
}
.section-larger{
  padding: 80px 0px ;
}
.section-larger-last{
  padding: 30px 0px 80px 0px;
}
.bg-img-right {
	-size: 85%;
}
.hero-text {
	text-align: center;
	padding: 0px 0px 40px 0px;
}
.hero-text h1 {
	font-weight: 300;
	padding-bottom: 10px;
}
.hero-text-inner {
	width: 100%;
	padding: 50px 0px;
	margin-top: 20px;
}
.hero-text-inner .sub-heading-1 {
  font-size: 17px;
}
.navbar-light .navbar-toggler {
	color: rgba(0, 0, 0, 0.5);
  border:0px;
  padding-right: 15px;
  position: absolute;
  right: 0;
  top: 4px;
}

.numbers {
	padding: 0px 0px 30px 0px;
}
.numbers {
  padding: 30px;
  margin-bottom: 20px;
}
.col-lg-4:nth-last-child(1) .numbers{
  margin: 0px 0px 0px 0px;
}
.bg-color-10{
  background-color: #DCDCDC;
  padding-left: 40px;
  padding-right: 40px;
}

.bullet-points ul li {
  font-size: 15px;
}

.slider-text-bg {
	width: 100%;
	height: 280px;
	padding: 0px;
	position: relative;
}

.title-center{
  text-align: center;
  padding-bottom: 30px;
}
.title-center h2 {
  padding-bottom: 10px;
}

.sub-heading-1, .sub-heading-2{
  color: #f23750;
  font-size: 18px;
}
.navbar-light .navbar-nav .nav-link {
  font-size: 14px;
  color: #222222;
  transition: all .3s ease-in-out;
  padding: 20px 15px;
  
}
.bg-dark {
	padding: 0px 0px;
	display: block;
	margin: 0px 15px;
}
.bg-dark.scrolled .nav-link{
  color: #111111;
  font-size: 14px;
}

.dropdown-menu a{
  font-size: 14px;
}
.offers ul li p, .offers a{
  font-size: 13px;
}
.client {
  text-align: center;

}
.client:nth-child(1){
  margin-bottom: 30px;
}
footer{
  padding: 50px 0px 0px 0px
}
footer .copyright {
	margin-top: 50px;
}
footer p{
    font-size: 14px;
}

footer li, footer a{
  font-size: 14px;
}
.list-icon span::before {
  font-size: 40px;
}

.icon-cntr span::before {
  font-size: 60px;
}
.point-list{
  padding: 0px;
}
.point-list .list-text{
  margin-left: 80px;
}
.point-list ul li{
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #cccccc;
}

.point-list ul li:nth-last-child(1){
  padding-bottom: 0px;
  margin-bottom: 50px;
  border-bottom: none;
}

.point-list ul li:nth-last-child(1) {
	padding-bottom: 0px;
	margin-bottom: 0px;
	border-bottom: none;
}

.feedback-list {
	background-size: contain;
	padding: 30px 30px 0px 30px;

}

.feedback p{
  position: relative;
  top: 20px;
}
.feedback-pic-left {
	width: 100px;
	height: 100px;
	border: 5px solid #ffffff;
	border-radius: 50%;
	overflow: hidden;
	position: relative;
	left: 0px;
	top: -70px;
}
.feedback-text-left {
	padding: 0px 0px 0px 0px;
	position: relative;
	top: -40px;
}

.team-list .team:nth-child(1){
  margin-bottom: 30px;
}

.img-container-step img {
width: auto;
max-height: 160px;
}
.reason-sec-back {
height: 310px;
background-size: 100%;
background-attachment: fixed;
}
.product h6 {
font-size: 15px;
font-weight: 600;
padding: 0;
}
.pro-icons span::before {
font-size: 40px;
}
.product .pro-icons {
padding-bottom: 15px;
}
.col-icon-cntr-claim {
width: 100%;
background-color: #f23750;
padding: 20px;
}

.col-icon-cntr-claim:hover {
padding: 20px;
box-shadow: 0px 10px 20px rgba(0, 0, 0, .2);
}
.no-of-package .package{
  margin-top: 30px;
}

.highlight h5 {
	line-height: 30px;
}

.high-img img {
	display: block;
	position: absolute;
	top: -73px;
	max-width: 100%;
}

.package{
  background-size: 100%;
  padding: 30px;
  border-radius: 15px;
  transition: all .3s ease-in-out;
}
.package:hover{
  background-size: 110%;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, .3);
}
.duration h4 {
font-size: 50px;
line-height: 25px;
}
.duration h4 span {
font-size: 15px;
}
.package ul li {
font-size: 14px;   
}

.package ul li::after {
content: '';
display: block;
background-size: contain;
width: 16px;
height: 16px;
position: absolute;
right: 0;
top: 25px;
}

.icon-cntr-claim span::before {
font-size: 48px;
}
.reason-sec {
	border-radius: 20px;
	padding: 60px 30px 30px 30px;
	box-shadow: 0px 10px 30px rgba(0, 0, 0, .1);
	position: relative;
	margin-top: -150px;
	z-index: 1;
}

.factors{
  margin-top: 30px;
}
.img-container-sml img {
  max-width: 100%;
}
}

@media (min-width: 992px) and (max-width: 1199.98px) {

    p{
        font-size: 14px;
        color: #333333;
        line-height: 1.5rem;
        padding: 0;
        margin: 0;
        letter-spacing: .5px;
    }



.section-first{
  padding: 100px 0px 40px 0px;
}
.section{
  padding: 40px 0px ;
}
.section-last{
  padding: 40px 0px 100px 0px;
}


.section-large-first{
  padding: 70px 0px 25px 0px;
}
.section-large{
  padding: 70px 0px ;
}
.section-large-last{
  padding: 25px 0px 70px 0px;
}


.section-larger-first{
  padding: 100px 0px 40px 0px;
}
.section-larger{
  padding: 100px 0px ;
}
.section-larger-last{
  padding: 40px 0px 100px 0px;
}
.hero-text {
	text-align: center;
	padding: 0px 0px 40px 0px;
}
.hero-text h1 {
	font-weight: 300;
	padding-bottom: 20px;
}
.hero-text-inner {
	width: 100%;
	padding: 50px 0px;
	margin-top: 20px;
}
.bg-img-right {
	background-size: 85%;
}

    .bg-color-10{
      background-color: #DCDCDC;
      padding-left: 60px;
      padding-right: 60px;
  }

  .slider-text-bg {
    height: 300px;
  }
    .bullet-points ul li {
      font-size: 15px;
    }
    
    .title-center{
      text-align: center;
      padding-bottom: 50px;
    }
    .title-center h2 {
      padding-bottom: 10px;
    }
    .sub-heading-1, .sub-heading-2{
      color: #f23750;
      font-size: 20px;
    }
    .navbar-light .navbar-nav .nav-link {
      font-size: 14px;
      color: #222222;
      transition: all .3s ease-in-out;
      padding: 20px 15px;
      
    }
  
    .bg-dark.scrolled .nav-link{
      color: #111111;
      font-size: 14px;
    }

    .dropdown-menu a{
      font-size: 14px;
    }
    .offers ul li p, .offers a{
      font-size: 13px;
    }

    footer p{
        font-size: 14px;
    }
   
    footer li, footer a{
      font-size: 14px;
    }
    .list-icon span::before {
      font-size: 40px;
    }

    .icon-cntr span::before {
      font-size: 60px;
    }
    .point-list {
      padding: 0px;
    }
    .point-list .list-text{
      margin-left: 60px;
    }
    .point-list ul li{
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #cccccc;
    }
  
    .point-list ul li:nth-last-child(1){
      padding-bottom: 0px;
      margin-bottom: 0px;
      border-bottom: none;
    }
    .feedback p{
      position: relative;
      top: 20px;
  }

  .img-container-step img {
    width: auto;
    max-height: 160px;
  }
  .reason-sec-back {
    height: 310px;
    background-size: 90%;
    background-attachment: fixed;
  }
  .product h6 {
    font-size: 17px;
    font-weight: 600;
    padding: 0;
  }
  .pro-icons span::before {
    font-size: 44px;
  }
  .product .pro-icons {
    padding-bottom: 15px;
  }

  .high-img img {
    display: block;
    position: absolute;
    top: -96px;
    width: 90%;
  }
  .col-icon-cntr-claim {
    width: 100%;
    background-color: #f23750;
    padding: 30px;
  }
  
  .col-icon-cntr-claim:hover {
    padding: 30px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, .2);
  }
  .package {
    background-size: 110%;
    padding: 30px;
    border-radius: 15px;
    transition: all .3s ease-in-out;
  }
  .package:hover{
    background-size: 120%;
    box-shadow: 0px 15px 25px rgba(0, 0, 0, .3);
    }
  .duration h4 {
    font-size: 50px;
    line-height: 25px;
  }
  .duration h4 span {
    font-size: 15px;
  }
  .package ul li {
    font-size: 14px;   
  }

  .package ul li::after {
    content: '';
    display: block;
    background-size: contain;
    width: 16px;
    height: 16px;
    position: absolute;
    right: 0;
    top: 25px;
  }

  .icon-cntr-claim span::before {
    font-size: 60px;
  }

  .insure-form .form-group legend {
    display: block;
    padding-right: 20px;
  }
}

@media (min-width: 1200px) and (max-width: 1599.98px) {
}

