
@import "./ColorCodes.scss";

.hero-notification {
    background-color: #E4F2F5;
    border-radius: 25px;
    opacity: 0.8;
    width: 85%;
    max-width: 1450px;
    text-align: left;
    padding: 0% 2%;
    margin: 4%;

    .text-notification {
        font-size: 1.25rem;
        text-align: left;

        b {
            color: rgb(0 58 253);
        }
    }

    @media screen and (max-width: 800px) {
        width: 95%;
        background-color: #E4F2F5;
        border-radius: 25px;
        opacity: 0.8;
        text-align: left;
        padding: 0% 4%;

        .text-notification {
            font-size: .65rem;
            text-align: left;

            b {
                color: rgb(0 58 253);
            }
        }
    }
}

.text-notification {
    font-size: 1.1rem;
    text-align: left;

    b {
        color: rgb(0 58 253);
    }

    @media screen and (max-width: 800px) {
        font-size: .65rem;
        text-align: left;

        b {
            color: rgb(0 58 253);
        }
    }
}

.hero-text-body {
    display: flex;
    justify-content: center;
    overflow-wrap: break-word;
    flex-wrap: wrap;
    gap: 25px;
    padding: .25rem 0rem;

    @media only screen and (max-width:800px) {
        display: flex;
        justify-content: center;
        overflow-wrap: break-word;
        flex-wrap: wrap;
        gap: 5px;
        padding: .25rem 0rem;
    }
}

.hero-subheadline {
    width: 95%;
    word-break: normal;

    @media only screen and (max-width:800px) {
        font-size: .9rem;
        line-height: 25px;
        width: 90%;
        font-family: 'Poppins-Regular';
        word-break: normal;
    }
}

.hero-headline {
    font-family: 'Poppins-Regular';
    font-size: 3rem;
    line-height: normal;
    color: $Fire-Brick !important;
    word-break: break-word;
    text-align: center;

    @media only screen and (max-width:800px) {
        font-size: 1.2rem;
        line-height: 25px;
        width: 90%;
        color: $Fire-Brick !important;
        font-family: 'Poppins-Regular';
        word-break: break-word;
        text-align: center;
        line-height: 33px;
    }
}

.image-media-object {
    max-width: 350px;
    border-radius: 30px;
    background-size: 60%;

    @media screen and (max-width: 800px) {
        background-size: 40% !important;
        width: 300px !important;
        height: 100% !important;
    }
}

.background-image-media-object {
    display: flex;
    justify-content: center;
    align-items: start;
    align-content: start;
    background-repeat: no-repeat;
    background-position: -14px 0px;
    width: 500px;
    height: 350px;
    background-size: 80%;

    @media only screen and (max-width:800px) {
        display: flex;
        justify-content: center !important;
        height: 100%;
        padding: 4% 0%;
        width: auto;
    }
}

.media-object-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
}

.media-object-overlay {
    background-repeat: no-repeat;
    overflow: visible;
    width: 350px;
    min-height: 240px;
}

.media-object-img {
    height: 150px;
    width: 240px;
    border-radius: 20px;
}

.content-header-list {
    @media only screen and (max-width:800px) {
        margin: auto;
        text-align: center !important;
    }
}

.UI-body {
    flex-wrap: wrap;
    min-height: 50px;
    max-width: 30vw;
    padding-top: 10px;
    padding-bottom: 10px;

    @media only screen and (max-width:900px) {
        max-width: 100%;
        min-height: 100%;
        border-bottom: 1px solid #DCDCDC;
        padding: 5px 0px;
        flex-wrap: wrap;
    }
}

.UI-body-last {
    min-height: 50px;
    max-width: 30vw;
    padding-top: 10px;
    padding-bottom: 10px;

    @media only screen and (max-width: 900px) and (min-width:00px) {
        max-width: 100%;
        min-height: 100%;
        border-bottom: 0px solid #DCDCDC;
        padding: 5px 0px;
        flex-wrap: wrap;
    }
}

.card-spacing {
    margin: 1rem !important;

    @media only screen and (max-width:800px) and (min-width:00px) {
        margin: .2rem !important;
    }
}

.card-icon {
    height: 80px;
    width: 80px;
}

.card-grid {
    padding: 1em;
    margin: 0 2px 2px 0;
}

.link-btn-sec {
    padding-left: 25%;
    padding-right: 25%;
    justify-content: center;

    @media only screen and (max-width:1000000px) and (min-width:1950px) {
        padding: 0px 30%;
    }

    @media screen and (max-width: 600px) {
        padding: 0px 5%;
    }
}


.publish-alert {
    display: contents;
}

.expired-alert {
    display: none;
}

li.filler-faq-bullets {
    display: flex;
    align-content: center;
    align-items: center;

    @media screen and (max-width: 800px) {
        display: block;
        word-break: keep-all;
    }
}

.filler-faq-title {
    color: #222222;
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 35px;
}

.filler-faq-description {
    color: #222222;
    font-family: Poppins;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 23px;
}

.announcement-month {
    color: #222222;
    font-family: Poppins;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 42px;
}

.default-link-btn {
    background-color: transparent;
    font-size: 15px;
    text-align: center;
    width: 100%;
    justify-content: start;
    padding: auto;
    color: #222222;
}

.announcement-landing {
    opacity: 0.8;
    border-radius: 14px;
    background-color: #FFFFFF;
    border-radius: 12px;
    font-size: 20px;
    color: #333333;
    border: none;
    padding: 10px 50px;
    text-align: left;
    width: 100%;

    &:hover {
        opacity: 1;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, .2);
    }
}

.main-announcement-body {
    background-color: white;
    border-radius: 12px;
    font-size: 20px;
    color: #333333;
    border: none;
    padding: 30px 50px;
    text-align: left;
    width: 100%;
    min-height: auto;
}

.announcement-body {
    background-color: white;
    border-radius: 12px;
    font-size: 20px;
    color: #333333;
    border: none;
    padding: 10px 50px;
    text-align: left;
    width: 100%;
    min-height: auto;
}

.main-title {
    font-size: 1.25rem !important;
}

.main-text {
    font-size: 1.75rem !important;
}

.announcement-title {
    min-height: 21px;
    width: 100%;
    color: #B22727;
    font-family: Poppins;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 23px;
}

.announcement-date {
    min-height: 21px;
    width: 100%;
    color: #222222;
    font-family: Poppins;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 23px;
}

.announcement-description {
    min-height: 21px;
    width: 100%;
    color: #222222;
    font-family: Poppins;
    font-size: 1rem;
    letter-spacing: 0;
    line-height: 23px;
}

.announcement-text {
    min-height: 21px;
    width: 100%;
    color: #222222;
    font-family: Poppins;
    font-size: 1rem;
    letter-spacing: 0;
    line-height: 23px;
}

.announcement-icon {
    height: 64px;
    width: 64px;
}

.CT-txt {
    max-width: 300px;
    font-family: "Poppins-Regular";
    font-size: 18px;
}

@media only screen and (max-width: 640px) {

    .layout {
        display: flex;
    }
}

@media only screen and (max-width:800px) {
    .media-object-overlay {
        background-repeat: no-repeat;
        background-position: center;
        overflow: visible;
        width: 260px !important;
        height: auto !important;
        max-height: 200px;
    }

    .media-object-img {
        margin: 40px 40px 0px 40px !important;
        height: 110px !important;
        width: 170px !important;
        border-radius: 14.5%;
        justify-content: center;
    }
}


.training-video-body {
    box-sizing: border-box;
    min-height: 106px;
    width: 100%;
    border: 1px solid #222222;
    border-radius: 13px;
    background-color: #FFFFFF;
    align-content: center;
    align-items: center;
    display: inline-grid;

    .video-log-date {
        color: #222222;
        font-family: "Poppins-Regular";
        font-size: 0.75rem;
        letter-spacing: 0;
        line-height: 25px;
        padding: 0px 15px;
    }

    .video-log-title {
        color: #222222;
        font-family: Poppins;
        font-size: 1rem;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 25px;
        padding: 5px 15px;
    }

    .date-span {
        color: #222222;
    }

    button {
        background-color: transparent;
        width: 100%;
        height: 100%;
    }

    span {
        font-size: 12px;
        margin: 0px -5px;
    }

    &:hover {
        background-color: #F0F0F0;
    }
}

.active-video-body {
    box-sizing: border-box;
    min-height: 106px;
    width: 100%;
    border: 1px solid #222222;
    border-radius: 13px;
    background-color: #222222;
    align-content: center;
    align-items: center;
    display: inline-grid;

    .video-log-date {
        color: #FFFFFF;
        font-family: "Poppins-Regular";
        font-size: 0.75rem;
        letter-spacing: 0;
        line-height: 25px;
        padding: 0px 15px;
    }

    .date-span {
        color: #FFFFFF;
    }

    .video-log-title {
        color: #FFFFFF;
        font-family: Poppins;
        font-size: 1rem;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 25px;
        padding: 5px 15px;
    }

    button {
        background-color: transparent;
        width: 100%;
        height: 100%;
    }

    span {
        font-size: 12px;
        margin: 0px -5px;
    }
}

.training-video-section {
    box-sizing: border-box;
    min-height: 459px;
    width: 1300px;
    border: 1px solid #979797;
    border-radius: 19px;
    background-color: #FFFFFF;
    padding: 50px 35px;

    @media screen and (max-width: 800px) {
        flex-wrap: wrap;
        display: flex;
        justify-content: center;
        height: auto;
        width: 100%;
    }
}


.text-section-heading {
    font-family: "Poppins-Regular";
    font-size: 2rem;
    text-align: center;

    @media screen and (max-width: 800px) {
        font-size: 1.5rem;
    }
}

.card {
    .hover-text {
        display: none;
    }

    &:hover {
        .hover-text {
            display: contents;
            color: #222222;
        }
    }
}

.container-training-video {
    width: 600px;

    @media screen and (max-width: 800px) {
        width: 100%;
    }
}

.list-container-training-video {
    width: 600px;

    @media screen and (max-width: 800px) {
        width: 100%;
    }
}

.top-newsletter-body {
    height: 300px;
    width: 100%;
    border-radius: 25px 30px;
    box-shadow: 0 5px 16px 0 rgba(141,141,141,0.5);
    padding: 25px 20px;

    @media screen and (max-width: 1000px) {
        flex-wrap: wrap;
        height: auto;
        justify-content: center;
        padding: 25px 20px;
    }
}

.regular-newsletter-body {
    height: 216px;
    width: 100%;
    border-radius: 15px;
    background-color: #FFFFFF;
    box-shadow: 0 5px 16px 0 rgba(141,141,141,0.5);
    padding: 25px 20px;

    @media screen and (max-width: 800px) {
        flex-wrap: wrap;
        height: auto;
        justify-content: center;
    }
}

img.newsletter-image {
    height: 100%;
    width: auto;

    @media screen and (max-width: 800px) {
        width: 100%;
        height: 100%;
    }
}

img.top-newsletter-image {
    height: 100%;
    width: auto;

    
    @media screen and (max-width: 800px) {
        width: 100%;
        height: 100%;
    }
}

.newsletter-topic {
    color: #808080;
    font-size: 1rem;
    font-family: 'Poppins-Regular';
    line-height: 23px;
}

.newsletter-page-section {
    width: 1330px;

    @media screen and (max-width: 1200px) {
        width: auto;
    }
}

@media screen and (max-width: 800px) {
    .mobile-newsletter-title {
        h4 {
            font-size: 1.2rem !important;
        }
    }
}

.newsletters-cards {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-gap: 1rem;

    @media (min-width: 900px) {
        grid-template-columns: repeat(2, 1fr);
    }
    /* Screen larger than 900px? 3 columns */
    @media (min-width: 1000px) {
        justify-content: center;
        grid-template-columns: repeat(3, 1fr);
    }
}

.newsletter-btn-section {
    display: flex;
    justify-content: end;

    @media (max-width: 1000px) {
        justify-content: center;
        align-items: center;
        justify-items: center;
        align-items: center;
    }

    .newsletter-button {
        width: 300px;

        @media (max-width: 1000px) {
            width: 80%;
        }
    }
}

.section {
    width: 90%;
    height: 167px;
    position: relative;
    overflow: hidden;
    justify-content: center;
}

.component-spacing {
    padding: 2%;
}

.login-obj-img {
    height: 450px;
    max-width: 390px;
    display: block;

    @media only screen and (max-width:800px) {
        max-height: 350px;
        max-width: 300px;
    }
}
