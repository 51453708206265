$Fire-Brick: #B22727;
$Sangria: #84040F;
$Nero: #222222;
$Apple:#449E38;
$Blue-Lagoon: #006E7F;
$Sherpa-Blue: #014854;
$Grey: #808080;
$Gainsboro:#DCDCDC;
$White-Smoke:#F0F0F0;
$Light-Cyan:#E4F2F5;
$Selective-Yellow:#FFBB00;
$Tangerine:#EE8700;

$card-btn-clr:#B22727;
$Default-BasiCard:#004C78;

$Hero-subHeadline: $Nero;
$Hero-Headline: $Fire-Brick;

$ContentHeader-Headline:#222222;

$FAQObject-Body:#FFFFFF;
$FAQ-Question:#222222;

$White-Card-Title-Size: 19px;

$Required-Asterisk: #B22727;
$RQ-Header: #222222;

$Nav-Background:#FFFFFF;
$Nav-Text-Enhanced:#B22727;
$Nav-Text:#222222;
$Nav-Text-Main-Hover:#B22727;
$Nav-Main-Background:transparent;
$Nav-Arrow-Hover:#B22727;

$Nav-SubRow-Bar:#B22727;
$Nav-SubRow-Border:transparent;
$Nav-SubRow-Background: #F0F0F0;
$Nav-SubRow-Hover-Text:#B22727;
$Nav-SubRow-Text:#FFFFFF;

$Nav-Dropdown-Bar:#B22727;
$Nav-Dropdown-Background:#F0F0F0;
$Nav-Dropdown-Text:#222222;
$Nav-Dropdown-Hover-Text:#FFFFFF;
$Nav-Dropdown-Hover-Background:#B22727;

$Breadcrumb-Separator: #808080;
$Breadcrumb-Crumbs:#808080;
$Breadcrumb-Last:#B22727;

$White: #FFFFFF;
$White-Smoke:#F0F0F0;
$Primary:#B22727;
$Primary-Hover:#7E1818;
$Secondary: #222222;
$Secondary-Hover:#ffffff;
$Cerulean:#004C78;
$Cerulean-Hover:#003554;
$RichTxt-HyperLink:$Fire-Brick;
$RichTxt-HyperLink-Hover:#7E1818;

$btn-txt-size: 20px;
$btnOval-txt-size: 20px;
