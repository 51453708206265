.carousel-container {
    position: relative;
    text-align: center;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    .carousel {
        display: flex;
        transition: transform 0.5s ease-in-out;
        width: 100%;

        &.animating {
            transition: transform 0.5s ease-in-out; /* Smooth animation */
        }

        .carousel-slide {
            flex-shrink: 0;
            width: 100%;
            display: flex;
            justify-content: center;

            img {
                max-width: 100%;
                height: auto;
                object-fit: contain;
            }
        }
    }

    .carousel-button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background: transparent;
        color: white;
        padding: 10px;
        border: none;
        cursor: pointer;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
            font-size: 24px;
        }

        &.prev-button {
            left: 0;
        }

        &.next-button {
            right: 0;
        }
    }
}
