@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body{
    background: #f0f0f0;
    margin: 0px;
    padding: 0px;
    font-family: 'Poppins', sans-serif;
}
.q{
    border: transparent;
    border-radius: 0px !important;
    background-color: #FFFFFF ;
}

p{
    font-size: 15px;
    color: #333333;
    line-height: 1.7rem;
    padding: 0;
    margin: 0;
    letter-spacing: .5px;
}

li{
    font-size: 14px;
    color: #4c4c4c;
    list-style: none;
    padding: 0;
    margin: 0;
}
ul{
    padding: 0;
    margin: 0;
}
a{
    text-decoration: none;
}

a:hover{
    text-decoration: none;
}

span.text-black{
    color: #222222;
}

.bg-color-dark p,
.bg-color-dark h1,
.bg-color-dark h2,
.bg-color-dark h3,
.bg-color-dark h4,
.bg-color-dark h5,
.bg-color-dark h6,
.bg-color-grad p,
.bg-color-grad h1,
.bg-color-grad h2,
.bg-color-grad h3,
.bg-color-grad h4,
.bg-color-grad h5,
.bg-color-grad h6
{color: #ffffff;}



.bg-color-dark .sub-heading-2,
.bg-color-grad .sub-heading-2{
    color: #2c1a26;
	margin: 20px 0;
	padding: 0px 0px 0px 20px;
	border-left: 2px solid #2c1a26;
	font-weight: 500;
	line-height: 30px;
	display: block;
}

.sub-heading-1, .sub-heading-2{
    color: #B22727;
    font-size: 22px;
}
.bg-color-dark .sub-heading-1,
.bg-color-grad .sub-heading-1{
    color: #2c1a26;
    font-size: 22px;
}

.sub-heading-2{
    margin: 20px 0;
    font-weight: 500;
    line-height: 30px;
    display: block;
}

.btn{
    color: #f4f4f4;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 5px;
    padding: 15px 20px !important;
    position: relative;
    z-index: 1;
}

.btn:hover{
    color: #ffffff;
}    

.btn-prim{
    background-color: #333;
    border: 0px;
    border-radius: 0px;
    padding: 10px;
    display: inline-block;
    line-height: 1rem;
}    
.btn-prim-round{
    background-color: #333;
    border: 0px;
    border-radius: 60px;
    padding: 10px;
    display: inline-block;
    line-height: 1rem;
}    
.btn-prim-round:hover{
    background-color: #111111;
}    
.btn-ter-round{
    color: #333333;
    background-color: transparent;
    border: 1px solid #666666;
    box-sizing: border-box;
    border-radius: 60px;
    padding: 10px;
    display: inline-block;
    line-height: 1rem;
}    
.btn-ter-round:hover{
    color: #333333;
    background-color: #d7d2c4;
}    

.btn-ter-light{
    border: 1px solid rgba(255, 255, 255, .2);
    border-radius: 0px;
    padding: 10px;
    display: inline-block;
    line-height: 1rem;
}    

.btn-ter-light:hover{
    border: 1px solid rgba(255, 255, 255, 0);
}    

.btn-ter-dark{
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 0px;
    padding: 10px;
    display: inline-block;
    line-height: 1rem;
    margin-top: 30px;
    color: #333333;
}    


.btn-link{
    background-color: transparent;
    color: #f23750;
    border: 0px;
    padding: 10px 0;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.btn-link:hover{
    color: #333333;
    text-decoration: none !important;
    
}


.accordion{
    width:auto;
    background-color: transparent !important;
}
button, .accordion-button{

    width: 590px; 
    max-width:1000px;
    border-color: transparent !important;
    outline:none !important;
    border-radius: 14px;
    text-align: left;
    font-size: 15px;
}

.card {
    
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color:#472f2f;
	background-clip: border-box;
    margin-bottom: 10px;
    border-bottom-left-radius: 0px;

}
.card-body{
    background-color: #ffffff !important;
    height:auto !important;
}

.card-body .btn-link{
    display: block;
    margin-top: 20px;
    padding: 0;
}
.card-header:first-child {
	border-radius:0px;
}
.accordion > .card:first-of-type {
	border-bottom: 0;
	border-radius: 10px;
}
.accordion > .card:not(:first-of-type) .card-header:first-child {
	border-radius: 0px;
}
.accordion > .card:not(:first-of-type):not(:last-of-type) {
	border-bottom: 0;
	border-radius: 0px !important;
}
.accordion > .card:last-of-type {
    border-radius: 0px !important;
}
.accordion > .card .card-header {
	padding: 0px;
	margin-bottom: 0px;
    background-color:#a59797;
    border: 0px !important;
    border-radius: 0px !important;
    transition: all .3s ease-in-out;
}

.accordion > .card .card-header:hover {
    background-color:#e86f4b;
}
.accordion > .card .card-header:hover h2 button{
    color: #533a3a;
}
.card-header h2{
    padding-bottom: 0px;
    transition: all .3s ease-in-out;
}
.card-header h2:hover{
    padding-left: 10px;
}
.card-header h2::before{
    content: '';
    position: absolute;
    left:0px;
    top:0px;

}
.card-header h2 button{
    color: #e86f4b;
    text-transform: initial;
    letter-spacing: initial;
    font-weight: 500;
    font-size: 16px;
    width: 100%;
    text-align: left;
    margin-top: 0;
}

.card-header h2 button:visited{
   text-decoration: none;
}


.vertical-cntr{
    display: flex;
    align-items: center;
    justify-content: center;    
}

.col-icon-bg-cntr {
    padding: 30px 25px;
    border-radius: 15px;  
    position: relative;
    transition: all .4s ease-in-out;
    
}
.col-icon-bg-cntr:hover{
    padding: 50px 20px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, .2);
}
.col-text-cntr  p{
    opacity: 0;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0px;
    transition: all .4s ease-in-out;

   
    @media only screen and (max-width:500px){
        &:hover{
        opacity: 1;
        
        transition: all .4s ease-in-out;
        }
    }
    
}
.col-icon-bg-cntr:hover .col-text-cntr p{
    opacity: 1;
    display: block;
    top: 10px;
    transform: translateY(-20px);
    @media only screen and (max-width:1281px) and (min-width:800px){
        transform: translateY(-45px);  
    }
    
}

.col-text-cntr {
    text-align: center;
    position: relative;
    transition: all .4s ease-in-out;
}



.col-icon-bg-cntr h6{
    transition: all .4s ease-in-out;
}
.col-icon-bg-cntr:hover h6{
    transform: translateY(-50px);
}

.col-icon-bg-cntr:hover .icon-cntr{
    transform: translateY(-30px);    
    .card-icon{
        width: 60px;
        height: 60px;
    }

    @media only screen and (max-width:1281px) and (min-width:800px){
        transform: translateY(-45px);  
    }
}

.icon-cntr {
    text-align: center;
    margin: 0 auto;
    transition: all .4s ease-in-out;
}

.icon-cntr span::before{
    line-height: normal;
    display: inline;
    margin: 10%;
    background: -webkit-linear-gradient(#ffac37, #e22c65);
  
    -webkit-text-fill-color: transparent;
    transition: all .4s ease-in-out;
}

.col-icon-bg-cntr .icon-cntr{
	padding-bottom: 10px;
}

.img-container{
  padding: 0px 0px 0px 0px;
}
.img-container div{
  max-width: 100%;
  height: auto;
  position: relative;
}

.text-container-right{
    padding-left: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.text-container-left{
    padding-right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.container-text{
    max-width: 100%;
}


.bullet-points ul{
    padding: 10px 0px;
    
}

.bullet-points ul li {
    font-size: 16px;
    font-weight: 500;
    color: #333333;
	padding: 10px 0px;
	position: relative;
    left: 15px;
    display: block;
}

.bullet-points ul li::before {
	content: '';
	position: absolute;
	display: block;
	left: -15px;
	width: 7px;
	height: 7px;
	background-color: #f23750;
	border-radius: 50%;
	top: 18px;
}

.section-first{
    padding: 120px 0px 60px 0px;
}
.section{
    padding: 60px 0px ;
}
.section-last{
    padding: 60px 0px 120px 0px;
}


.section-large-first{
    padding: 90px 0px 45px 0px;
}
.section-large{
    padding: 90px 0px ;
}
.section-large-last{
    padding: 45px 0px 90px 0px;
}

.body{
    height:500px;
}
.faq{
        font-family: 'Poppins-Regular';
       
        min-height: 60px;
        width: 1111.24px;
        border-radius: 14px;
        /* background-color: tomato !important; */
  
}
.section-larger-first{
    padding: 120px 0px 60px 0px;
}
.section-larger{
    padding: 30px 0px  ;
}
.section-larger-last{
    padding: 60px 0px 120px 0px;
}

.section-padding-none{
    padding: 0px ;
}
.ml-15{ margin-left: 15px;}

.mt-20{ margin-top: 20px;}
.mt-30{ margin-top: 30px;}
.mt-60{ margin-top: 60px;}
.mt-90{ margin-top: 90px;}
.mb-30{ margin-bottom: 30px;}
.mb-60{ margin-bottom: 60px;}
.mb-90{ margin-bottom: 90px;}
.mt-negative-20{ margin-top: -20px;}
.mt-negative-30{ margin-top: -30px;}
.mb-negative-30{ margin-bottom: -30px;}
.mt-negative-60{ margin-top: -60px;}
.mb-negative-60{ margin-bottom: -60px;}
.mt-negative-90{ margin-top: -90px;}
.mb-negative-90{ margin-bottom: -90px;}

.bg-color{
    background-color: #ffffff;
}

.bg-color-pink{
    background-color: #f93156;
}

.bg-color-2{
    background-color: #e86f4b;
}
.bg-color-dark{
    background: -moz-linear-gradient(-45deg, #fbd4bb 0%, #f93156 100%);
    background: -webkit-linear-gradient(-45deg, #fbd4bb 0%, #f93156 100%);
    background-size: contain;
}
.bg-color-grad{
    background: -moz-linear-gradient(-45deg, #e7765f 0%, #e7765f 100%);
    background: -webkit-linear-gradient(-45deg, #e7765f 0%, #e7765f 100%);
    background: linear-gradient(-120deg, #e7765f 30%, #e7765f 100%);
}

.bg-color-10{
    background-color: #DCDCDC;
    padding-left: 90px;
    padding-right: 90px;
}
.bg-img-left{
    background-size: contain;
}

.bg-img-left-2{
    background-size: contain;
}
.bg-img-right{
    background-size: 55%;
}
.bg-img-right-half{
    background-size: cover;
}
.full-bg-color{
    background-size: cover;
}
.full-bg-img{
    background-size: cover;
}


.navbar-brand{
    font-size: 1.5rem;
    font-weight: 700;
    letter-spacing: 5px;
}

.navbar-brand img{
    width: auto;
    max-height: 25px;
    padding: 0px 0px;
    margin-top: 12px;
}

.bg-dark {
    width: 100%;
    background-color: #ffffff !important;
    padding: 0px 30px;
    border-radius: 15px;
    box-shadow: 0px 5px 30px rgba(0, 0, 0, .2);
    transition: all .5s ease-in-out;
    display: flex;
    position: relative;
}
.navbar-light .navbar-nav .nav-link {
    font-size: 15px;
    color: #222222;
    transition: all .3s ease-in-out;
    padding: 20px 15px;
    
}

.navbar-light .navbar-nav .nav-link:hover {
    color: #f23750 !important;

}

.navbar-light .navbar-nav .active .nav-link {
	color: #f23750 !important;
}

.bg-dark.scrolled{
    padding-top: 0px;
    padding-bottom: 0px;
    box-shadow: 0px 5px 30px rgba(0, 0, 0, .2);
    background-color: #ffffff !important;
}

.bg-dark.scrolled .nav-link{
    color: #111111;
    font-size: 15px;
}


.navbar-expand-lg .navbar-nav .dropdown-menu {
    margin-top: 0px;
	background: #ebebeb;
	border: none;
    border-radius: 0px 0px 10px 10px;
    overflow: hidden;
    padding: 0px;
    transition: all .1s ease-in-out;
    border-top: 3px solid #f23750;
}



.dropdown-menu .dropdown-item{
    display: block;
    width: 100%;
    padding: 15px 10px;
    clear: both;
    font-weight: 400;
    color: #666666;
    text-align: left;
    white-space: nowrap;
    background-color: transparent;
    box-shadow: none;
}

.dropdown-menu .dropdown-item:hover{
    color: #ffffff;
    white-space: nowrap;
    background-color: #f23750;
    
}

.dropdown-menu .dropdown-item::before{
    content: '-';
    font-weight: bold;
    color: #ffffff;
    padding-right: 5px;
    margin-left: -5px;
    opacity: 0;
    transition: all .2s ease-in-out;
}
.dropdown-menu .dropdown-item:hover::before{
    content: '-';
    font-weight: bold;
    color: #ffffff;
    padding-right: 10px;
    margin-left: 3px;
    opacity: 1;
}


.dropdown-menu a{
    font-size: 15px;

}


.hero-image{
    height: 1000px ;
    flex-direction: column;
    background-size: cover;
    padding-top: 100px;
   
}


.hero-text h5{
    color: #333333;
}
.hero-text{
    text-align: center;
    padding: 20px 0px 40px 0px;
    
}

.hero-text h1{
    font-weight: 300;
    padding: 0;
}

    footer{
       background-color: #111111;
        background-attachment: fixed;
        background-size: cover;
        padding: 60px 0px 0px 0px;
    }
    footer p{
        padding: 0;
        color: #999999;
        letter-spacing: .5px;
        font-size: 15px;
    }

    footer li, footer a{
        color: #999999;
        font-size: 15px;
        padding: 5px 0;
        letter-spacing: .5px;
        transition: all .2s ease-in-out;
    }
    footer a:hover{
        text-decoration: none !important;
        color: #ffffff;
        padding-left: 6px;
    }
    footer h4, footer h5{
        color: #ffffff;
        line-height: 1.7rem;
    }
    footer .copyright{
        margin-top: 60px;
        border-top: 1px solid #333333;
        padding: 20px 0px;
    }
    footer .bottom{
        margin-top: 0px;
        border-top: 1px solid #333333;
        padding: 0px 0px;
        margin-bottom: -50px;
    }
    footer .copyright p{
        color: #666666;
    }

    .copyright-links ul{
        float: left;
    }

    .copyright-links ul li{
        display: inline;
        padding-right: 20px;
    }
    .copyright-links ul li a{
        color: #666666;
        transition: all .1s ease-in-out;
        padding: 0;
    }
    .copyright-links ul li a:hover{
        color: #cccccc;
        padding: 0;
    }

    .copyright-social ul{
        float: right;
    }
    .copyright-social ul li{
        width: 35px;
        display: inline-block;
        padding: 0px 0px 0px 10px ;
    }
    .copyright-social ul li a{
        padding: 0;
        opacity: .4;
        transition: all .3s ease-in-out;
    }
    .copyright-social ul li a:hover{
        opacity: 1;
    }
   

.form-home {
	width: 100%;
    background:#a52b46;
    padding: 30px;
	border-radius: 20px;
    color: #ffffff;
}

.form-home .form-control::placeholder {
	color: rgba(255, 255, 255, .8);
    opacity: 1;
    font-size: 14px;
}

.form-home .form-control{
	display: block;
	width: 100%;
	height: auto;
	padding: 15px 20px 15px 0px;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #ffffff;
	background-color: transparent;
	background-clip: padding-box;
	border-top: 0px;
	border-right: 0px;;
	border-bottom: 1px solid rgba(255, 255, 255, .5);
	border-left: 0px;
	border-radius: 0px;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-check-inline {
	position: relative;
	display: inline-block;
	padding: 0;
}

.form-head {
	padding: 0px 0px 10px 0px;
}

.form-head h6{
	padding: 0;
}

.form-head p{
	font-size: 14px;
}

.plan-selected ul li{
    background-color: #f23750;
    border-radius: 20px;
    padding: 30px;
}
.plan-selected ul li h5, .plan-selected ul li h5 span, .plan-selected ul li p {
    color: #ffffff;
}
.plan-selected ul li h5{
    font-weight: 600;
}

.plan-selected ul li h5 span{
    font-size: 70%;
    font-weight: 600;
}

.plan-selected .form-check-input{
    margin: 25px 0px 0px 0px;
    position: relative;
    float: right;
    padding: 20px;
}

.plan ul li{
    border: 1px solid #cccccc;
    border-radius: 20px;
    padding: 30px;
    margin-bottom: 20px;
    transition: all .3s ease-in-out;
}
.plan ul li:hover{
    background-color: #f23750;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, .1);
    border: 1px solid transparent;
}

.plan ul li h5{
    font-weight: 600;
    color: #f23750;
    transition: all .3s ease-in-out;
}
.plan ul li:hover h5, .plan ul li:hover h5 span, .plan ul li:hover p {
    color: #ffffff;
}
.plan ul li p{
    font-size: 13px;
    color: #333333;
    transition: all .4s ease-in-out;
}

.plan ul li h5 span{
    font-size: 70%;
    font-weight: 600;
    color: #555555;
    transition: all .3s ease-in-out;
}
.plan .form-check-input{
    margin: 25px 0px 0px 0px;
    position: relative;
    float: right;
    padding: 20px;
}
.offers{
    margin-bottom: 50px;
}
.offer-text{
    margin: 0px 0px 0px 40px;
}

.offers ul li{
    position: relative;
    border-bottom: 1px solid #cccccc;
    padding: 0px 0px 20px 0px;
    margin: 0px 0px 20px 0px;
}

.offers ul li h6{
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    padding: 0;
}
.offers ul li p{
    font-size: 14px;
    color: #666666;    
}

.offers .list-icon{
    float: left;
}
.offers a{
    font-size: 14px;
    color: #302c2c;
    text-decoration: underline;
    font-weight: 600;
    position: absolute;
    right: 0;
    top: 0;
}

.offers .list-icon span::before{
    font-size: 30px;
    font-weight: 500;
}
.car-info{
    padding-left: 30px;
    border-left: 2px solid #f23750;
    
}
.car-info li{
    padding: 10px 0px;
}
.send-quotes h6, .send-quotes p {
	color: #ffffff;
}
.send-quotes{
    margin-top: 50px;
    padding: 30px;
    border-radius: 20px;
}
.quote-text{
    padding-top: 5px;
    margin-top: 10px;
    border-top: 1px solid #cccccc;
}
.quote-text ul li{
    width: 30%;
    text-align: center;
    display: inline-block;
}
.quote-text p{
    font-size: 14px;
    padding: 0;
    line-height: normal;
}
.quote-text span::before{
    color: #ffffff;
    font-size: 30px;
    padding: 15px;
    margin: 0px 0px 0px 0px;
}

.insure-text{
    background-color: #a52b46;
    border-radius: 20px;
    padding: 5px 5px 5px 30px;
    margin-bottom: 30px;
}
.insure-text h4, .insure-text h6, .insure-text p{
    color: #ffffff;
}

.insure-form h5{
    padding-bottom: 20px;
}
.insure-form h6{
    padding-top: 10px;
    padding-bottom: 20px;
    font-size: 16px;
    font-weight: 600;
}

.insure-form .form-group label{
    display: inline;
    padding-right: 10px;
}

.insure-form .form-group{
    margin-bottom: 25px;
}

.insure-form .form-control{
    display: block;
	width: 100%;
	height: auto;
	padding: 15px 0px 15px 0px;
	font-size: 15px;
	font-weight: 400;
	line-height: 1.5;
	color: #666666;
	background-color: transparent;
	background-clip: padding-box;
	border-top: 0px;
	border-right: 0px;;
	border-bottom: 1px solid rgba(0, 0, 0, .3);
	border-left: 0px;
	border-radius: 0px;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.insure-form .form-control::placeholder {
	color: #666666;
    opacity: 1;
    font-size: 15px;
}

.numbers {
    padding: 30px;
    background-color: #ffffff;
    background-size: cover;
    border-radius: 30px;
    box-shadow: 0px;
    position: relative;
    transition: all .4s ease-in-out;
}
.numbers .number-icons{
    max-width: 60px;
    height: auto;
    position: absolute;
    top: 30px;
    right: 30px;
    opacity: .1;
    transition: all .4s ease-in-out;
}
.numbers:hover .number-icons{
    max-width: 50px;
    top: 15px;
    right: 30px;
    opacity: .5;
}
.numbers:hover{
    box-shadow: 0px 10px 30px rgba(0, 0, 0, .1);
}

.numbers h2{
    color: #f23750;
    font-weight: 600;
}

.numbers h2 span{
    color: #222222;
    font-weight: 400;
    font-size: 18px;
    display: block;
}

.point-list{
    padding: 0px 20px 0px 0px;
}

.point-list .list-icon{
    float: left;
}

.list-icon span::before{
    font-size: 48px;
    font-weight: 500;
    line-height: normal;
    display: inline;
    margin: 0;
    background: -webkit-linear-gradient(#ffac37, #e22c65);
   
    -webkit-text-fill-color: transparent;
}

.point-list .list-text{
    margin-left: 30px;
}

.point-list .list-text h6{
    color: #333333;
    font-weight: 500;
}

.point-list ul li{
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid #d7d2c4;
}

.point-list ul li:nth-last-child(1){
    padding-bottom: 0px;
    margin-bottom: 0px;
    border-bottom: none;
}



.feedback {
    position: relative;
}
.feedback p{
    position: relative;
    top: 20px;
}
.feedback span, .feedback h2 {
    position: relative;
    z-index: 100;
}
.feedback > img{
    position: absolute;
    top: -25%;

}
.feedback-list{
    background-size: contain; 
    padding: 30px 30px 0px 30px;
    box-sizing: border-box;
}
.feedback-content-left {
    padding: 70px 0px;
    position: relative;
}

.item-1 {
      
	padding: 0px 3px 5px 3px;
}
.feedback-pic-left {
	width: 100px;
	height: 100px;
	border: 5px solid #ffffff;
    border-radius: 50%;
	overflow: hidden;
    position: relative;
    left: 30px;
    top: -50px;
}
.feedback-text-left{
   padding: 0px 30px 30px 30px;
   position: relative;
   top: -20px;
}

.feedback-text-left h6 span{
   font-size: 15px;
   color: #666666;
   display: block;
}

.client img{
    justify-content: center;
    opacity: 1;
    transition: all .3s ease-in-out;
}

.client img:hover{
    justify-content: center;
    opacity: .9;
    transform: scale(1.05);
}

.hero-small-about {
	background-size: 100%;
}
   .product{
       text-align: center;
       margin-bottom: 30px;
   }
   
   .pro-border::after{
    
       content: '';
       width: 2px;
       height: calc(100%);
       background-color: #cccccc;
       position: absolute;
       right: 0;
       top: 0;
    
       
   }
   .pro-border:nth-last-child(1)::after{
       content: '';
       width: 0px;
       height: calc(100%);
       position: absolute;
       right: 0;
       top: 0;
       padding: 0px;
       
   }
   
   .product h6{
       font-size: 18px;
       font-weight: 600;
       padding: 0;
   }
   .product .pro-icons{
       padding-bottom: 20px;
       transition: all .5s cubic-bezier(.95,.50,.58,5);
   }

   .product:hover .pro-icons{
       transform: translateY(-15px);
   }

   .pro-icons span::before {
	font-size: 48px;
	font-weight: 500;
	line-height: normal;
	display: inline;
	margin: 0;
	background: -webkit-linear-gradient(#ffac37, #e22c65);

	-webkit-text-fill-color: transparent;
	transition: all .4s ease-in-out;
}


.team{
    text-align: center;
    height: auto;
    padding: 15px;
    border: 1px solid #ffffff;
    box-shadow: 0px;
    position: relative;
    overflow: hidden;
    transition: all .25s ease-in-out;
    
}

.team:hover{
    box-shadow: 0px 10px 10px rgba(0, 0, 0, .1);
}

.team img{
    transition: all .25s ease-in-out;
}
.team:hover img{
    transform: scale(1.1) translateY(5px);
    filter: grayscale(5);
}

.team-info{
    background-color: #ffffff;
    width: 100%;
    text-align: left;
    padding: 20px;
    position: absolute;
    bottom: 0px;
    left: 0;
    color: #222222;
}

.team-info h5{
    color: #222222 !important;
    padding-bottom: 0;
}

.team-info p{
    color: #f23750 !important;
    font-size: 14px !important;
}

.full-section-team{
    background-color: #d7d2c4;
    padding: 120px 15px;
    margin: -15px;
}

.full-section-team .title-center p, .full-section-team .title-center h2{
    color: #ffffff;
}

.hero-image-small {
    width: 100%;
	min-height: 400px;
	overflow: hidden;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}

.hero-text-inner {
    width: 100%;
    padding: 0px;
}

.hero-text-inner h3{
    font-weight: 600;
    padding: 0;
}

.hero-text-inner .sub-heading-1{
    font-size: 20px;
}
.hero-small-car {
    background-color: #222222;
}

.img-container-step{
    text-align: center;
}
.img-container-step img {
	width: auto;
	max-height: 170px;
}
.img-container-sml img {
	width: auto;
	max-height: 120px;
}
.steps{
    margin-bottom: 60px;
}

.step-head{
    padding: 0;
    margin-top: 30px;
}
.step-head h6{
    color: #333333;
    padding: 0px 0px 0px 20px;
    font-weight: 500;
    line-height: 30px;
}
.step-text{
    width: 100%;
    float: left;
    padding-top: 20px;
}

.step-count{
    min-width: 70px;
    height: 60px;
    padding: 0px 10px;
    background-color: #cccccc;
    text-align: center;
    overflow: hidden;
}
.step-count h1{
    font-size: 80px;
    font-weight: bold;
    color: #e8e8e8;
}

.package{
    background: #e8e8e8 url(https://cdn.lonestarmga.com/img/Product-Card.png) no-repeat top center;
    background-size: 100%;
    padding: 15px 30px 30px 30px;
    
    border-radius: 25px;
    transition: all .3s ease-in-out;
}
.package:hover{
    background: #ffffff url(https://cdn.lonestarmga.com/img/Product-Card-Hover.png) no-repeat top center;
    background-size: 110%;
    box-shadow: 0px 20px 30px rgba(0, 0, 0, .3);
}
.package h5{
    line-height: 35px;
    padding-bottom: 90px;
    text-align: center;
    font-size: 22px;
    font-family: 'Poppins-Bold';
    color:#FFFFFF;
}
.package ul{
    margin-top: 10px;
}

.package ul li{
    font-size: 16px;
    padding: 20px 0px;
    border-bottom: 1px solid#b7b2a6;
    position: relative;
}

.package ul li.active{
    color: #111111;
}




.package ul li:hover{
    color: #111111;

}
.duration h4{
    font-size: 56px;
    line-height: 30px;
    font-weight: bold;
    color: #f23750;
    text-align: center;
}

.duration h4 span{
    font-size: 16px;
    font-weight: normal;
    color: #666666;
}




.package ul li:last-child{
    border-bottom: 0px;
}
.package .btn{
    border-radius: 40px;
    width: 60%;
    background-color: #B22727;
    letter-spacing: 1px;
    font-size: 14px;
    font-family: 'Poppins-Bold';
    color:#FFFFFF;
}

.package .btn:hover{
    background-color: #84040F;
    font-size: 14px;
    font-family: 'Poppins-Bold';
    color:#FFFFFF;
}


.col-icon-cntr-claim {
    width: 100%;
    background: linear-gradient(0deg, #84040F 0%, #BE0A22 34.69%, #84040F 100%);

    padding: 50px 30px;
    border-radius: 15px;  
    position: relative;
    overflow: hidden;
    transition: all .4s ease-in-out;
    
}

.col-icon-cntr-claim .circle-1 {
	width: 300px;
	height: 300px;
	border-radius: 50%;
	background-color: tomato;
	opacity: .3;
	position: absolute;
	top: 100px;
	left: 0px;
}
.col-icon-cntr-claim .circle-2 {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: #2c1a26;
    opacity: .1;
    position: absolute;
    top: 0px;
    right: 0px;
}


.icon-cntr-claim span::before {
	font-size: 70px;
	font-weight: 500;
	line-height: normal;
	display: inline;
	margin: 0;
	color: #ffac37;
	transition: all .4s ease-in-out;
}
.col-icon-cntr-claim:hover .icon-cntr-claim span::before {
	color: #84040F;
    background: linear-gradient(0deg, #ffffff 0%, #ffffff 34.69%, #84040F 100%);

}
.col-text-cntr-claim h6{
	color: #ffffff;
	transition: all .4s ease-in-out;
}
.col-icon-cntr-claim:hover .col-text-cntr-claim h6{
	color: #333333;

    @media only screen and (max-width:1282px) and (min-width:800px){
        transform: translateY(-400px);  
    }
}
.col-text-cntr-claim {
	text-align: center;
	position: relative;
	transition: all .4s ease-in-out;
}
.col-icon-cntr-claim:hover{
    background-color: #DCDCDC;
    padding: 50px 30px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, .2);
}
.col-icon-cntr-claim .icon-cntr-claim {
	padding-bottom: 30px;
}
.icon-cntr-claim {
	text-align: center;
	margin: 0 auto;
	transition: all .4s ease-in-out;
}

.high-img{
    position: relative;
}

.high-img img{
    display: block;
    position: absolute;
    max-width: 100%;
}
.highlight h5, .highlight-top h5{
    line-height: 40px;
}

.highlight h5, .highlight .text-pink {
    color: #ffffff;
}

.col-img-cntr{
    margin-top: 0px;
    margin-bottom: 0px;
}
.img-cntr-claim img{
    max-width: 70%;
    height: auto;
    margin: 0px auto 5px auto ;
    display: block;
    
}
.col-text-claim{
    text-align: center;
}

.col-text-claim h6{
    font-weight: 600;
}
.hero-small-bike {
	background-size: 100%;
}

.bike-works span{
    font-size: 120px;
    font-weight: bolder;
    color: #cccccc;
    padding: 0;
    line-height: 0px;
    position: relative;
    top: 50px;
    z-index: -1;
}

.works-text h6{
    font-weight: 500;
    color: #333333;
    line-height: normal;
}

.reason-sec{
    border-radius: 20px;
    padding: 60px 60px 30px 60px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, .1);
    position: relative;
    
    z-index: 1;
}

.reason-sec-back{
    height: 360px;
    background-size: 95%;
    background-attachment: fixed;
}

.col-icon-left-sml{
    padding-bottom: 30px;
}
.icon-left-sml{
    float: left;
}
.text-left-sml{
    margin-left: 60px;
}

.text-left-sml p, .text-left-sml h6{
    color: #333333;
}
.text-left-sml h6{
    font-weight: 500;
}


.col-icon-left{
    width: 100%;
	background-color: #ffffff;
	padding: 30px;
    border-radius: 15px;
    margin-bottom: 30px;
	transition: all .3s ease-in-out;
}
.col-icon-left h6{
    font-weight: 500;
}
.col-icon-left:hover{
    background-color: #ffffff;
    box-shadow: 0px 20px 30px rgba(0, 0, 0, .2);
}
.icon-left{
    text-align: left;
    padding-bottom: 40px;
}
.icon-left span::before {
	font-size: 48px;
	font-weight: 500;
	line-height: normal;
	display: inline;
	margin: 0;
	background: -webkit-linear-gradient(#ffac37, #e22c65);
	
	-webkit-text-fill-color: transparent;
	transition: all .4s ease-in-out;
}

.bike-claim .col-img-cntr{
    margin-top: 0px;
    margin-bottom: 30px;
}

div.bike-claim .col-img-cntr:nth-child(3){
    margin-bottom: 0px;
}
div.bike-claim .col-img-cntr:nth-child(4){
    margin-bottom: 0px;
}

.hero-small-contact {
	background-size: 100%;
}
.contact{
    padding-bottom: 60px;
}
.contact img{
    max-width: 38px;
    height: auto;
    margin-bottom: 15px;
}

.map-container {
	overflow: hidden;
	position: relative;
    min-height: 500px;
    margin: 0px -15px;
}
.map-container iframe {
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	position: absolute;
}

.form-group {
	margin-bottom: 1rem;
}
label {
    font-size: 15px;
	display: inline-block;
	margin-bottom: 0.5rem;
}
.form-control::placeholder {
	color: #999999;
    opacity: 1;
    font-size: 14px;
}
.form-group textarea.msg-area{
    border-radius: 10px;
}
.form-control {
	display: block;
	width: 100%;
	height: auto;
	padding: 15px 20px;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #111111;
	background-color: #ffffff;
	background-clip: padding-box;
	border: 1px solid #bfbdb0;
	border-radius: 50px;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-group .form-control {
	border-radius: 50px !important;
}

.input-group-append {
	margin: 0px;
	position: absolute;
    right: 0;
    top: 5px;
    z-index: 100;
}

