@import "../Style/Components.scss";
@import "./ColorCodes.scss";


h1 {
    font-family: 'Poppins-Regular';
    font-size: 2.75rem;
    line-height: 3.1875rem;

    @media only screen and (max-width:550px) {
        font-size: .5rem;
        line-height: normal;
    }
}

h2 {
    font-family: 'Poppins-Regular';
    font-size: 2.5rem;
    line-height: 3.5rem;
}

h3 {
    font-family: 'Poppins-Regular';
    font-size: 2rem;
    line-height: 2.8125rem;
}

h4 {
    font-family: 'Poppins-Regular';
    font-size: 1.75rem;
    line-height: 2.4375rem;
}

h5 {
    font-size: 1.4rem;
    line-height: 2.0625rem;
    font-family: 'Poppins-Regular';

    @media only screen and (max-width:550px) {
        font-size: 1rem;
        line-height: normal;
    }
}

h6 {
    font-size: .8rem;
    font-family: 'Poppins-Regular';
}

a {
    color: #000;
}

li.a {
    font-size: 1px;
    list-style-type: circle;
}

* {
    font-family: 'Poppins';
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 0px solid rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
        margin-top: 0rem;
        margin-bottom: 0rem;
    }
}

.bullet-red {
    color: #B22727;
    font-size: 20px;
    padding-right: 5px;

}

.blue-bullet {
    display: flex;
    align-content: center;
    align-items: center;
    padding-right: 5px;
}

.grey-background-E8 {
    background-color: #E8E8E8 !important;
}

@font-face {

    font-family: 'Poppins-Regular';
    src: url(https://cdn.lonestarmga.com/fonts/Poppins-Regular.ttf);
}

@font-face {
    font-family: 'Poppins-Medium';
    src: url(https://cdn.lonestarmga.com/fonts/Poppins-Medium.ttf);
}

@font-face {
    font-family: 'Poppins-Semi-Bold';
    src: url(https://cdn.lonestarmga.com/fonts/Poppins-SemiBold.ttf);
}

@font-face {
    font-family: 'Poppins-Bold';
    src: url(https://cdn.lonestarmga.com/fonts/Poppins-Bold.ttf);
}

.enable-pointer {
    cursor: pointer !important;
}

/* IE11 hide native button () */
select::-ms-expand {
    display: none;
}

.dark-txt {
    color: #000 !important;
}

.light-txt {
    text-align: center !important;
    color: white !important;
}

.block {
    display: block;
}

.grid {
    display: grid;
}

.p-5-full {
    padding: 1rem;

    @media only screen and (max-width: 800px) {
        padding: 0rem;
    }
}

.px-4-full {
    padding: 0rem 1.5rem;

    @media only screen and (max-width: 800px) {
        padding: 0rem;
    }
}

.text-align-center {
    text-align: center;
}

.paragraph-text {
    line-height: auto;
    color: #222222;
    font-family: "Poppins-Regular";
    font-size: 1.1rem;
    letter-spacing: 0;
    margin: 0px !important;
}

.content-header-description {
    max-width: 944px;
    color: #000000;
    font-family: "Poppins-Regular";
    font-size: 24px !important;
    line-height: 5px;
    letter-spacing: 0;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.content-only-description {
    font-family: "Poppins-Regular";

    p {
        font-size: 18px;
    }
}

.header-image {
    min-height: 900px;
    text-align: center;
    min-height: 1100px;
    width: 100%;
    padding-top: 2%;
    background-size: cover;
    text-align: center;
}

.header-image-mobile {
    height: 425px;
    text-align: center;
    width: 100%;
    padding-top: 2%;
    text-align: center;
}

.banner-sub-heading {
    font-family: "Poppins-Bold";
    color: #B22727;
    font-size: 1.375em;

    @media only screen and (max-width: 800px) {
        font-size: 1.3rem;
    }
}

.banner-heading {
    font-size: 2.5em;
    font-family: 'Poppins-Regular';

    @media only screen and (max-width: 800px) {
        font-size: 1.3em;
    }
}

.page-start-text {
    color: #222222;
    font-family: "Poppins-Regular";
    font-size: 19.5px;
    padding: 0% 0% 0% 0%;
    flex-wrap: wrap;
    width: 1010px;
}

.help-icon {
    width: 20px;
    height: 20px;
    margin-left: 1%;
    background: url(https://cdn.lonestarmga.com/img/tooltip_default.png);
    background-size: 90%;
    background-repeat: no-repeat;
}

.help-icon:hover {
    width: 20px;
    height: 20px;
    margin-left: 1%;
    background-image: url(https://cdn.lonestarmga.com/img/tooltip_active.png);
    background-size: 90%;
    background-repeat: no-repeat;

    .help-text {
        color: #B22727 !important;
    }

    .help-text {
        color: #B22727 !important;
    }
}

.help-text {
    color: #565656;
    font-family: 'Poppins-Regular';
    font-size: 15px;
}


.h-dark {
    .sub-headline {
        letter-spacing: 1px;
        margin-bottom: 0px;
        font-size: 22px;
        font-family: 'Poppins-Bold';
        color: #b22727;
    }

    .heading {
        font-family: 'Poppins-Regular';
        font-size: 40px;
        color: black;

        @media only screen and (max-width:700px) {
            font-size: 25px;
            line-height: normal;
        }
    }
}

.borderless {
    border: 2px solid transparent !important;

    &:hover {
        background-color: white !important;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, .2);
    }
}

.red-card {
    width: 255px;
    height: 240px;
    background: linear-gradient(180deg, #BE0A22 0%, #8A040F 100%);
    padding: 50px 20px 50px 20px;
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    text-align: center;
    color: #FFFFFF;

    .square-card-title {
        font-size: 1.5rem;
        font-family: 'Poppins-Semi-Bold' !important;
    }

    a {
        color: #FFFFFF;
    }

    font-size: 1.5rem;

    &:hover {
        .hover-square-icon {
            width: 75px !important;
            height: 65px !important;
            padding: 2px;
        }

        background: linear-gradient(0deg, #FFFFFF 0%, #FFFFFF 34.69%, $White-Smoke 100%);
        box-shadow: 0px 10px 30px rgba(0, 0, 0, .2);
        color: #B22727;
        transition: all .0s ease-out;

        a {
            color: #B22727;
        }
    }
}

.MUC-Style {
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.23);
    max-height: 550.27px;
    max-width: 535px;
    border-radius: 20px;
    background-color: #FFFFFF;

    @media only screen and (max-width: 800px) {
        max-height: 704.27px;
        max-width: 335px;
    }
}

.MUC-Title {
    padding-left: 15px;
    height: 70px;
    min-width: 171px;
    color: $Nero;
    font-family: 'Poppins-Bold';
    font-size: 20px;
    letter-spacing: 0;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 800px) {
        font-size: 1.2rem;
        min-width: 25px;
        min-height: 40px;
    }
}

.MUC-Icon {
    width: 70px;

    @media only screen and (max-width: 800px) {
        width: 50px;
        height: 50px;
    }
}

.MUC-Description {
    min-height: 68px;
    color: #333333;
    font-family: 'Poppins-Regular';
    font-size: 18px;
    letter-spacing: 0;
    line-height: 31px;
}

.MUC-Hours {
    color: #565656;
    font-size: 15px;
    width: 141px;
    text-align: left;
    font-family: 'Poppins-Regular';
}

.tooltip {
    display: flex;
    background-color: white !important;
    width: 300px !important;
    min-height: 200px;
    justify-content: center;
    align-items: center;
    box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.33);
    border-radius: 25px !important;
    opacity: 1.0 !important;
}

.Hours-Card {
    min-width: 250px;
    min-height: 250px;
    padding-bottom: 15px;
}

.Hours-Card-header {
    font-family: 'Poppins-Bold';
    font-size: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: firebrick;
    padding: 10px 0px;
}

.hours-card-header2 {
    font-family: 'Poppins-Bold';
    font-size: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: firebrick;
    padding: 10px 0px 0px 0px;
}

.Operation-Day-txt {
    text-align: left;
}

.Hours-text {
    height: 25.89px;
    width: 109px;
    color: #222222;
    font-family: "Poppins-Regular";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 25px;
}

.Hours-Disclosure {
    height: auto;
    color: #222222;
    font-family: "Poppins-Regular";
    font-size: 12px;
    letter-spacing: 0;
    line-height: 23px;
}

.hours-disclosure2 {
    height: auto;
    color: #222222;
    font-family: "Poppins-Regular";
    font-size: 12px;
    letter-spacing: 0;
    line-height: 23px;
}

.ImageItem {
    width: 205px;
    border: 1px solid #DCDCDC;
}

.OrderItem-Spacing {
    padding-bottom: 2%;
}

.OrderItem {
    height: 73px;
    width: 73px;
    background-color: #e9e0e1;
    border-radius: 50px;
    padding-top: 20%;
    font-size: 22px;

    .OrderItemNum {
        line-height: 42px;
        color: #B22727;
        font-size: 26px;
        font-family: poppins-bold;
        text-align: center;
    }
}

.standard-page-width {
    width: 1330px;
}

.general-config {
    padding: 1% 1% 2% 2.5%
}

.ImageList-Content {
    padding-top: 5%;
}

.cardCTA {
    padding-top: 1.5%;
    margin-top: 4%;
    height: 295px;
    width: 350px;
    border-radius: 20px;
    background-color: #FFFFFF !important;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.21);
    justify-content: center;
    text-align: center;
    font-family: 'Poppins-Regular';

    .ctaIcon {
        min-height: 60px;
        width: 50px;
    }

    .ctaTagline {
        font-size: 15px;
        line-height: 35px;
        justify-content: center;
        margin: 1% 14%;
        text-align: center;
        letter-spacing: 0px;
    }
}

.container-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media only screen and (max-width: 1000px) {
        flex-direction: column;
    }
}

.flex-basis-0 {
    flex-basis: 1%;
}

.flex-basis-1 {
    flex-basis: 10%;
}

.flex-basis-1-5 {
    flex-basis: 20%;
}

.flex-basis-2 {
    flex-basis: 25%;
}

.flex-basis-2-full {
    flex-basis: 25%;

    @media only screen and (max-width:850px) {
        flex-basis: 100%;
    }
}

.flex-basis-3 {
    flex-basis: 30%;
}

.flex-basis-3-full {
    flex-basis: 30%;

    @media only screen and (max-width:850px) {
        flex-basis: 100%;
    }
}

.flex-basis-4 {
    flex-basis: 37.5%;
}

.flex-basis-4-full {
    flex-basis: 37.5%;

    @media only screen and (max-width:850px) {
        flex-basis: 100%;
    }
}

.flex-basis-5 {
    flex-basis: 50%;
}

.flex-basis-6 {
    flex-basis: 64.3%;
}

.flex-basis-6-full {
    flex-basis: 64.3%;

    @media only screen and (max-width:850px) {
        flex-basis: 100%;
    }
}

.flex-basis-5-full {
    flex-basis: 50%;

    @media only screen and (max-width:850px) {
        flex-basis: 100%;
    }
}

.flex-basis-7 {
    flex-basis: 75%;
}

.flex-basis-7-full {
    flex-basis: 75%;

    @media only screen and (max-width:850px) {
        flex-basis: 100%;
    }
}

.flex-basis-8 {
    flex-basis: 80%;
}

.flex-basis-9 {
    flex-basis: 90%;
}

.flex-basis-10 {
    flex-basis: 100%;
}

.flex-basis-auto {
    flex-basis: auto;
}

.gap-10 {
    gap: 100px;
}

.gap-8 {
    gap: 80px;
}

.gap-6 {
    gap: 60px;
}

.gap-5 {
    gap: 50px;
}

.gap-4 {
    gap: 40px;
}

.gap-3 {
    gap: 30px;
}

.gap-2 {
    gap: 25px;
}

.gap-1 {
    gap: 10px;
}

.col-flex {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    align-content: center;
    flex: 1;
}

.break-flex {
    flex-basis: 100%;
    height: 0;
}

.responsive {
    max-width: 500px;
    height: auto;
    width: 100%;
}

.parent {
    position: relative;
    top: 0;
    left: 0;
}

.image1 {
    position: relative;
    top: 0;
    left: 0;
}

.image2 {
    position: absolute;
    margin-left: 40px;
}

img.media-object-img-bottom {
    height: 250px;
}

.media-object-background-section {
    height: 350px !important;
}

ul.checkmark {
    font-family: 'Poppins-Regular';
    font-size: 22px;
    list-style: none;
    padding-left: 10px;
}


ul.checkmark li:after {
    content: '✓';
    transform: translateY(-35%);
    padding-left: 15px;
}

ul.checkmark li {
    font-family: 'Poppins-Regular';
    font-size: 22px;
    border-bottom: 1px solid black;
    width: 250px;
    padding: 12px 12px 10px 12px;
}

ul.checkmark li:nth-child(4n+1):nth-last-child(-n+4),
li:nth-child(4n+1):nth-last-child(-n+4)~li {
    border: none;
}

@media only screen and (min-width:500px) and (max-width:850px) {

    img.card-icon {
        width: 30px;
        height: 35px;
    }

    .square-card {
        justify-content: center;
        align-items: center;
        padding: 40px 0px;
        height: 220px;
        width: 240px;
        border-radius: 26px;
        font-size: 20px;
        font-family: 'Poppins-Regular';

        .hover-text {
            display: none;
            margin: auto;
        }

        &:hover {
            .hover-text {
                display: block;
            }

            padding: 40px 0px;
        }
    }

    .white-card {
        color: black !important;
        border: 1px solid #808080;
        background-color: #FFFFFF;

        &:hover {
            background-color: #E4F2F5;
            box-shadow: 0px 10px 30px rgba(0, 0, 0, .2);
            border: 2px solid #004C78;
        }
    }
}

.padding-right-5 {
    padding-right: 3rem;

    @media only screen and (max-width:550px) {
        padding-right: 0px;
    }
}

.margin-left-3 {
    margin-left: 1rem;

    @media only screen and (max-width:550px) {
        margin-left: 0px;
    }
}

.p-none {
    padding: 1.5rem;

    @media only screen and (max-width:550px) {
        padding: .5rem 0px !important;
    }
}

@media only screen and (max-width:550px) {
    img.card-icon {
        width: 35px !important;
        height: 35px !important;
    }

    .square-card-small {
        align-items: center;
        border-radius: 26px;
        font-family: 'Poppins-Regular';
        font-size: 15px;
        height: 110px !important;
        justify-content: center;
        padding: 5px !important;
        width: 250px;

        .hover-square-icon {
            width: 40px !important;
            height: 40px !important;
        }

        .square-icon {
            width: 45px !important;
            height: 45px !important;
        }

        .hover-text {
            display: none;
        }

        &:hover {
            .hover-text {
                display: block;
            }

            padding: 40px 20px;
        }
    }

    .square-card {
        padding: 5% 5px !important;
        box-sizing: border-box;
        height: auto !important;
        width: 316px !important;
        border-radius: 26px;
        font-family: 'Poppins-Regular';
        font-size: 16px;

        .hover-text {
            display: none;
            margin: auto;
        }

        &:hover {
            @media only screen and (max-width: 550px) {
                .square-card-title {
                    display: none;
                }

                height: 180px !important;

                .col-text-cntr p {
                    opacity: 1 !important;
                    transition: all .4s ease-in-out;
                }

                .hover-text {
                    display: block;
                }
            }

            .hover-text {
                display: block;
            }
        }
    }

    .white-card {
        color: $Nero;
        border: 1px solid #808080;
        background-color: #FFFFFF;

        &:hover {
            background-color: #E4F2F5;
            box-shadow: 0px 10px 30px rgba(0, 0, 0, .2);
            border: 2px solid #004C78;
        }
    }

    .mobile-spacing-cntr-m {
        margin-left: 5%;
        margin-right: 5%;
    }

    .mobile-spacing-left {
        padding-left: 8%;
    }

    .youtube-vid {
        height: 100% !important;
        width: 100% !important;
    }

    .youtube-vid-lg {
        height: 100% !important;
        width: 100% !important;
    }

    .cardCTA {
        margin: 14% 2% 0% 2%;
        padding-top: 4% !important;
        align-items: center;
        height: 280px;
        width: 95%;
        border-radius: 20px;
        background-color: #FFFFFF !important;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.21);
        justify-content: center;
        text-align: center;
        font-family: 'Poppins-Regular';

        .ctaIcon {
            min-height: 50px;
            width: 40px;
        }

        .ctaTagline {
            font-size: 14px;
            line-height: 35px;
            justify-content: center;
            margin: 2% 14%;
            text-align: center;
            letter-spacing: 0px;
        }
    }
}

.YT-Video {
    margin-left: auto;

    @media only screen and (max-width: 1000px) {
        margin-left: 0px;
        justify-content: center;
    }
}

.break-item {
    @media only screen and (max-width: 1000px) {
        flex-direction: column;
    }
}

.mo-image {
    display: flex;
    justify-content: center;
    align-items: start;
    align-content: start;

    @media only screen and (max-width:800px) {
        display: flex;
        justify-content: center !important;
    }
}

@media only screen and (max-width:1000px) {
    .mobile-breadcrumb {
        padding-left: 4%;
    }
}

.auto-products-sec {
    justify-content: center;
    align-items: center;
    width: 1250px;
    border-radius: 100px;
    padding: 100px 5px 5px 5px;

    @media only screen and (max-width:800px) {
        justify-content: center;
        align-items: center;
        width: 95% !important;
    }
}

@media only screen and (max-width:800px) {
    .wrap-button-cols {
        overflow-wrap: break-word;
        flex-wrap: wrap;
    }

    .mobile-breadcrumb {
        padding-left: 6%;
    }

    .system-section-PRO {
        padding: 1% 1% 0px 2%;
        justify-content: center;
        align-items: center;
        align-content: center;
    }

    .RQ-header {
        line-height: 33px !important;
        font-size: 22px !important;
    }

    .RQ-title-box {
        padding: 5% 5% 0% 5% !important;
    }

    .RQ-form-pos {
        padding: 0% !important;
    }

    .youtube-section {
        margin-right: 5% !important;
    }

    .productCard {
        width: 300px !important;

        h5 {
            font-size: 16px !important;
            height: 150px !important;
        }

        .innerText {
            width: 200px !important;
        }

        .package {
            background: #e8e8e8 url(https://cdn.lonestarmga.com/img/Product-Card.png) no-repeat top center;
            background-size: 100%;
            padding: 10px 10px 30px 10px !important;
            border-radius: 25px;
            transition: all .3s ease-in-out;
        }
    }

    .mo-text {
        padding: 0px 20px;
    }

    .mobile-adjust {
        width: 350px !important;
        margin: -1% !important;
    }

    .cont-horiz-white-card {
        height: 100% !important;
        width: 100% !important;
        border-radius: 23px;
        background-color: #FFFFFF;
        box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.19);
        justify-content: center;
        align-items: center;
        padding: 10% 0%;
    }



    .about-us-text-sec {
        margin-top: 5px !important;
    }

    img.media-object-img-bottom {
        height: 200px;
    }

    .media-object-background-section {
        height: 250px;
    }

    .paragraph-text {
        color: #333333;
        font-family: "Poppins-Regular";
        font-size: 15px !important;
        letter-spacing: 0;
        line-height: 31px !important;
    }

    img.rich-card-icon {
        width: 45px !important;
        height: 45px !important;
        padding-right: 1px;
    }

    img.rich-card-icon-sm {
        width: 57px;
        height: 55px;
    }

    img.rich-card-icon-md {
        width: 87px;
        height: 85px;
    }

    .claim-center-section {
        padding-top: 1.5%;
        padding-bottom: .5%;
        text-align: center;
        margin: 0% 3% 5% 3% !important;
        justify-content: center;
        align-items: center;
        border-radius: 36px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
    }

    .make-a-payment-section {
        padding-top: 2.5%;
        text-align: center;
        min-height: 900px;
        margin: 0% 5% 5% 5% !important;
        justify-content: center;
        align-items: center;
        border-radius: 36px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
    }

    .hover-text {
        display: none !important;
    }

    .square-card-title {
        font-family: "Poppins-Regular";
        font-size: .5rem !important;
        line-height: auto !important;
        height: 100% !important;
    }


    .red-card {
        width: 150px !important;
        height: 140px !important;
        background: linear-gradient(180deg, #BE0A22 0%, #8A040F 100%);
        padding: 3% 1px !important;
        display: inline-block !important;
        justify-content: center !important;
        align-items: center;
        align-content: center;
        border-radius: 15px;
        position: relative;
        overflow: hidden;
        text-align: center;
        color: #FFFFFF;

        a {
            color: #FFFFFF !important;
        }
    }

    .red-card:hover {
        background: linear-gradient(0deg, #FFFFFF 0%, #FFFFFF 34.69%, $White-Smoke 100%);
        box-shadow: 0px 10px 30px rgba(0, 0, 0, .2);
        color: #B22727;
        transition: all .0s ease-out;

        a {
            color: #B22727 !important;
        }
    }

    .page-start-text {
        font-size: 18px;
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        padding: 0% 5% 0% 5% !important;
    }

    .col-2-flex {
        padding-top: 2%;
        justify-content: center;
        display: inline-block !important;
        align-items: center !important;

        .col-1-sm-flex {
            width: 400px;
            height: auto;
            margin: 0px 0% 0px 0%;
        }

        .col-2-auto-flex {
            width: auto;
            height: auto;
            margin: 0px 0% 0px 2%;
        }
    }

    .FAQquestion {
        font-size: 14px !important;
        line-height: 20px !important;
    }

    .FAQ-Icon {
        margin-right: 5px;
        margin-bottom: 15px;
        font-size: 25px !important;
    }

    .FAQanswer {
        font-family: "Poppins-Regular";

        p {
            font-size: 15px !important;
        }

        padding: 8px;
    }

    .ImageList-Content {
        padding-top: 22%;
    }

    .gen-config {
        background-size: 90% !important;
    }

    .ImageItem {
        width: 245px;
        border: 1px solid #DCDCDC;
    }

    .OrderItem {
        height: 50px;
        width: 50px;
        background-color: #e9e0e1;
        border-radius: 50px;
        padding-top: 5%;

        .OrderItemNum {
            line-height: 42px;
            color: #B22727;
            font-size: 18px;
            font-family: poppins-bold;
            text-align: center;
        }
    }

    .ImageItem {
        width: 125px !important;
        border: 1px solid #DCDCDC;
        padding: 1%;
    }

    .general-config {
        padding: 0px 1% 2% 1.5%
    }

    .space-reg {
        padding-top: 4%;
    }

    .mobile-space-sm {
        margin-left: 7%;
    }

    .header-section {
        text-align: center;
        width: 100% !important;
        height: 250px !important;
        background-size: auto 100% !important;
    }

    .header-section-producer-portal {
        text-align: center;
        width: 100% !important;
        min-height: 250px !important;
        background-size: auto 100% !important;
    }

    .youtube-vid {
        width: 100%;
        height: 100%;
    }

    .youtube-vid-lg {
        height: 100% !important;
        width: 100% !important;
    }

    .content-object-heading {
        text-align: left;
        font-size: 22px !important;
    }

    .content-object-sub-heading {
        font-size: 100% !important;
    }

    .square-card-title {
        font-size: 1rem !important;
        text-align: center !important;
    }

    .card-m {
        margin-right: 3%;
        margin-left: -1%;
    }

    .card-oval {
        border-radius: 50%;
        height: 115px !important;
        width: 150px !important;
        position: relative;
        overflow: hidden;
        transition: all .4s ease-in-out;
    }

    .card-oval .title-text {
        font-size: 12px !important;
        margin: 10px 0px 0px 5px !important;
        line-height: 12px !important;
        height: 0px !important;
        color: #ffffff !important;
    }

    .card-oval .icon-image {
        max-width: 40px !important;
        height: 38px !important;
        margin: 0px 5px 0px 5px !important;
    }

    .card-oval .hover-icon-image {
        max-width: 40px !important;
        height: 38px !important;
    }

    .card-oval:hover .title-text {
        font-size: 12px !important;
        margin: 10px 0px 0px 5px !important;
        line-height: 12px !important;
        height: 0px !important;
    }

    .card-oval:hover .link-text {
        visibility: hidden !important;
    }
}

.grey-gradient-background {
    background: linear-gradient(0deg, #EFEFEF 0%, #F9F9F9 34.69%, #F0F0F0 100%);
}

.white-background {
    background-color: #FFFFFF !important;
}

.white-smoke-background,
.light-greyF0 {
    background-color: #F0F0F0 !important;
}

.gainsboro-background-DC {
    background-color: #DCDCDC !important;
}

.help-join {
    max-width: 630px;

    @media only screen and (max-width:1610px) and (min-width:1350px) {
        max-width: 600px;
    }

    @media only screen and (max-width:1350px) and (min-width:1100px) {
        max-width: 500px;
    }

    @media only screen and (max-width:1100px) and (min-width:00px) {
        max-width: 400px;
    }
}

@media only screen and (min-width:1281px) {
    .customer-WWO {
        padding: 0% 10% 0%;
    }
}

@media only screen and (min-width: 1024px) {
    .pl-4-desktop {
        padding-left: 4rem;
    }

    .lg-w {
        width: 1330px;
    }

    .about-Sec-1 {
        display: flex;
        justify-content: center;
        margin-top: -2%;
        margin-left: -5%;
    }

    .section-5 {
        min-height: 400px;
        width: auto;
    }

    .phone-sec {
        margin-left: 1.5%;
    }

    .questions {
        margin-left: 23.5%;
        margin-bottom: 2%;
    }

    .break {
        margin: 0px 35px;
        color: #CCCCCC;
        border-right: solid;
        border-width: 3px;
    }

    .break-btn-group {
        margin: 0px 35px;
        color: #CCCCCC;
        border-right: solid;
        border-width: 3px;
        width: .5px;
        height: 100%;
    }
}

.header-section-mobile {
    text-align: center;
    display: block;
    height: 250px !important;
    background-size: auto 100% !important;
    width: 100%;
}

@media only screen and (min-width:1300px) {
    .what-we-offer-sec {
        margin-left: 15%;
    }

    .xl {
        margin-left: 200px;
        justify-content: center;
    }
}

.youtube-vid {
    width: 440px;
    height: 259px;
}

.youtube-vid-lg {
    width: 500px;
    height: 280px;
}

@media screen and (min-width:1281px) and (max-width:5000px) {
    .square-card {
        align-items: center;
        border-radius: 26px;
        font-family: 'Poppins-Regular';
        font-size: 20px;
        height: 240px;
        justify-content: center;
        padding: 40px 0px;
        width: 270px;

        .hover-text {
            display: none;
            margin: auto;
        }

        &:hover {
            .hover-text {
                display: block;
            }

            padding: 40px 5px;
        }
    }

    .square-card-small {
        align-items: center;
        border-radius: 26px;
        font-family: 'Poppins-Regular';
        font-size: 20px;
        height: 200px;
        align-items: center;
        align-content: center;
        display: grid;
        justify-content: center;
        padding: 10px 20px;
        width: 240px;

        .hover-square-icon {
            width: 65px !important;
            height: 65px !important;
        }

        .square-icon {
            width: 65px !important;
            height: 65px !important;
        }

        .hover-text {
            display: none;
        }

        &:hover {
            .hover-text {
                display: block;
            }

            padding: 10px 20px;
        }
    }

    .white-card {
        border: 1px solid #808080;
        background-color: #FFFFFF;
        color: #000 !important;

        &:hover {
            background-color: #E4F2F5;
            box-shadow: 0px 10px 30px rgba(0, 0, 0, .2);
            border: 2px solid #004C78;
        }
    }
}

@media only screen and (max-width:1281px) {
    .lg-w {
        width: 1100px;
    }

    .breadcrumb-pos {
        margin-left: 25px;
    }

    img.help-icon {
        width: 30px;
        height: 20px;
    }

    .youtube-section {
        padding: 0px 5% 0px 12%;
    }

    .card-icon {
        height: 60px;
        width: 60px;
    }

    .square-card {
        justify-content: center;
        align-items: center;
        padding: 40px 0px;
        height: 220px;
        width: 250px;
        border-radius: 26px;
        font-size: 20px;
        font-family: 'Poppins-Regular';

        .hover-text {
            display: none;
            margin: auto;
            padding: 30px 20px;
        }

        &:hover {
            .hover-text {
                display: block;
            }

            padding: 40px 0px;
        }
    }

    .square-card-small {
        align-items: center;
        border-radius: 26px;
        font-family: 'Poppins-Regular';
        font-size: 20px;
        height: 200px;
        justify-content: center;
        padding: 40px 20px;
        width: 220px;

        .hover-square-icon {
            width: 65px;
            height: 65px;
        }

        .square-icon {
            width: 65px;
            height: 65px;
        }

        .hover-text {
            display: none;
        }

        &:hover {
            .hover-text {
                display: block;
            }

            padding: 40px 20px;
        }
    }

    .white-card {
        border: 1px solid #808080;
        background-color: #FFFFFF;
        color: black !important;

        .hover-text {
            display: none;
            margin: auto;
            padding: 30px 20px;
        }

        &:hover {
            background-color: #E4F2F5;
            box-shadow: 0px 10px 30px rgba(0, 0, 0, .2);
            border: 2px solid #004C78;
        }
    }

    .red-card {
        width: 220px;
        height: 200px;
        background: linear-gradient(180deg, #BE0A22 0%, #8A040F 100%);
        padding: 30px 20px 30px 20px;
        border-radius: 15px;
        position: relative;
        overflow: hidden;
        text-align: center;
        color: #FFFFFF;

        a {
            color: #FFFFFF !important;
        }
    }

    .red-card:hover {
        background: linear-gradient(0deg, #FFFFFF 0%, #FFFFFF 34.69%, $White-Smoke 100%);
        box-shadow: 0px 10px 30px rgba(0, 0, 0, .2);
        color: #B22727;
        transition: all .0s ease-out;

        a {
            color: #B22727 !important;
        }
    }
}

@media only screen and (max-width:800px) {
    .mobile-flex-10 {
        flex-basis: 100%;
    }

    .mobile-gap-none {
        gap: 0px !important;
    }

    .mobile-padding-bottom-10 {
        padding-bottom: 10%;
    }
}

.section-9 {
    background-color: #F0F0F0;
    min-height: 549px;
    padding-bottom: 2% !important;
    padding-top: 2%;

    @media only screen and (max-width:1300px) {
        padding: 2% 2% 0% 2%;
    }

    @media only screen and (max-width:800px) {
        padding: 0 5% 0% 5%;
    }
}

.accident-mobile-section {
    @media only screen and (max-width:800px) {
        padding: 0 5% 0% 10%;
    }
}

.faq-customer-section {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;

    @media only screen and (max-width:800px) {
        padding: 0 5% 0% 5%;
    }
}

.FAQbody {
    display: flex;
    flex-direction: column;
}

.accordion {
    border-radius: 14px;
    background-color: #FFFFFF !important;
    color: rgb(202, 166, 166);
    cursor: pointer;
    padding: 30px 30px 15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    outline: none;
    height: 61px;
}

.accordion:hover,
.active {
    background-color: pink;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, .2);
}

.active {
    border-radius: 14px 14px 0px 0px;
}

.FAQquestion {
    font-family: "Poppins-Regular";
    text-align: start;
    font-size: 16px;
    line-height: 23px;
    color: $FAQ-Question;
}

.FAQ-Icon {
    margin-right: 5px;
    margin-bottom: 15px;
    font-size: 30px;
    font-family: "Poppins-Regular";
    color: #B22727;
}

.accordion__content {
    border-radius: 0px 0px 14px 14px;
    background-color: $FAQObject-Body;
    overflow: hidden;
    word-wrap: break-word;
}

.FAQanswer {
    font-family: "Poppins-Regular";

    p {
        font-size: 15px;
    }

    padding: 10px 18px;
    word-wrap: break-word;
    word-break: break-all;
}

.continuous {
    margin: 10px 80px 10px 80px;
    border: solid;
    border-color: #cccccc;
    border-width: 2px;
    border-radius: 50px;
    padding-top: 300px;
    height: 100%;
}

.section-2 {
    margin-top: -120px;
    justify-content: center;
    min-height: 400px !important;
    z-index: 1;

    @media only screen and (max-width: 800px) {
        margin-top: 25px;
    }
}

.section-4 {
    display:grid;
    align-items:center;
    min-height: 660px;
}

.our-partners-container {
    padding: 0% 0% 2% 0%;
    min-height: 100px;
}

.help-join-padding {
    padding: 2.5% 0%;
}

.cardGrid-section {
    max-width: 800px;
}

.call-explore-about-icon {
    img {
        max-width: 50px;
        max-height: 50px;
    }

    a {
        color: #8A040F;
        font-size: 1.25rem;
    }

    color: #8A040F;
    font-size: 1.25rem;

}

.announcement-section {
    padding: 0px 0% 0% 6%;
}

.system-section-PRO {
    padding: 3% 0% 0px 0%;
    justify-content: center;
    align-items: center;
    align-content: center;
}


.make-a-payment-section {
    padding-top: 2.5%;
    text-align: center;
    min-height: 900px;
    max-width: 1200px;
    justify-content: center;
    align-items: center;
    border-radius: 36px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
}


.claim-center-section {
    padding-top: 2.5%;
    padding-bottom: 1.5%;
    margin-bottom: 4%;
    text-align: center;
    max-width: 1300px;
    justify-content: center;
    align-items: center;
    border-radius: 36px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
}

@media only screen and (min-width: 1900px) {

    .OrderItem {
        height: 93px;
        width: 93px;
        background-color: #e9e0e1;
        border-radius: 50px;
        padding-top: 25%;
        font-size: 30px;

        .OrderItemNum {
            line-height: 42px;
            color: #B22727;
            font-size: 30px;
            font-family: poppins-bold;
            text-align: center;
        }
    }

    .youtube-section {
        padding: 0 12% 0 25%;
    }
}

.section-faq {
    min-height: 30px;
    padding: 2% 15%;
    justify-content: center;
    align-items: center;
    background-color: #DCDCDC;

    @media only screen and (max-width: 800px) {
        min-height: 30px;
        padding: 5% 2% !important;
        justify-content: center;
        align-items: center;
        background-color: #DCDCDC;
    }
}

.progress-style {
    background: rgb(255, 255, 255);
    background: linear-gradient(129deg, rgba(255, 255, 255, 1) 10%, rgba(187, 187, 187, 1) 70%);
}

.my-modal {
    width: 70vw;
    max-width: 90vw;
}

.border-radius-2 div {
    border-radius: 50px !important;
}

.title-text {
    font-weight: bold;
}


img.rich-card-icon {
    width: 57px;
    height: 55px;
}

img.rich-card-icon-sm {
    width: 57px;
    height: 55px;
}

img.rich-card-icon-md {
    width: 87px;
    height: 85px;
}

.square-icon {
    width: 115px;
    height: 75px;

    @media only screen and (max-width:800px) {
        width: 55px !important;
        height: 50px !important;
        justify-content: center;
        align-items: center;
    }
}

.rich-card-title-sm {
    font-family: 'Poppins-Semi-Bold';
    font-size: 20px;
    padding-left: 1vw;
    text-align: start;

    @media only screen and (max-width:800px) {
        font-family: "Poppins-Regular";
        padding: 0% 0% 6% 0%;
    }
}

.Description {
    max-width: 28vw;

    @media only screen and (max-width:800px) {
        display: block;
        max-width: 100%;
    }
}

.height-larger {
    height: 100px;
}

.height-smaller {
    height: 90px;
}

.rich-card-title-md {
    font-family: "Poppins-Regular";
    font-size: 40px;
    width: 25vw;
    margin: 4% 0% 0px 44px;
    box-sizing: border-box;

    @media only screen and (max-width:800px) {
        font-family: "Poppins-Regular";
        padding: 0% 0% 6% 0%;
        word-wrap: normal !important;
        font-size: 25px;
        width: 100%;
    }
}

.hover-text-square {
    display: none;
}

.hover-square-link {
    display: none;
}

.square-card .hover-square {
    display: none;
}

.square-card:hover .hover-square {
    display: block;
}

.square-card:hover .hover-text-square {
    display: block;
    font-size: 16px;
    color: #000000;
}

.square-card:hover .hover-square-link {
    font-size: 16px;
    font-family: 'Poppins-Bold' !important;
    color: #B22727;
    text-decoration: underline;
    display: block;
}

img.hover-square-icon {
    width: 115px;
    height: 75px;
}

.square-card:hover .square-icon-image {
    display: none;
}

.card-oval {
    border-radius: 50%;
    height: 181px;
    width: 240px;
    position: relative;
    overflow: hidden;
    transition: all .4s ease-in-out;
    padding: 10px 15px 10px 10px;

    a {
        color: white !important;
    }
}

.card-oval:hover {
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.card-oval .icon-image {
    max-width: 70px;
    height: 68px;
    margin: 10px 25px 0px 25px;
}

.card-oval:hover .icon-image {
    display: none;
}

.card-oval:hover .hover-icon-image {
    display: block;
    margin: auto;
}

.card-oval .hover-icon-image {
    display: none;
    max-width: 60px;
    height: 40px;
    margin: 30px 25px 0px 25px;
}

.card-oval:hover .title-text {
    font-size: 1rem;
    width: auto;
}

.card-oval .link-text {
    visibility: hidden;
    font-size: .9rem;
    color: black;
    font-family: "Poppins-Regular";
}

.card-oval:hover .link-text {
    visibility: visible;
}

.card-oval .title-text {
    font-size: 20px;
    margin: 10px 22px 5px 25px;
    line-height: 22px;
    max-width: 320px;
    color: #ffffff !important;
}

.square-card-no-hover {
    align-items: center;
    border-radius: 26px;
    font-family: 'Poppins-Regular';
    font-size: 20px;
    height: 240px;
    justify-content: center;
    padding: 15% 0px;
    width: 270px;

    @media only screen and (max-width:800px) {

        height: auto;
    }
}

.square-card .square-card-title {
    padding-top: 10px;
    text-align: center !important;
    font-size: 20px;
    line-height: 28px;
    height: auto;
    width: 100%;
    font-family: "Poppins-Regular";
}

.square-card:hover .hover-square-icon {
    width: 115px;
    height: 75px;
}

ul.media-list li:before {
    display: inline-block;
    content: url(https://cdn.lonestarmga.com/img/bullet.svg);
    margin-top: 5px;
    margin-right: 15px;
}

.RichTxt-Hyperlink {
    color: $RichTxt-HyperLink;
    font-family: 'Poppins-Bold';
    /*      text-decoration: underline;*/
    word-break: keep-all;

    a {
        font-family: 'Poppins-Bold';
        text-decoration: underline;
        color: $RichTxt-HyperLink !important;
        word-break: keep-all;
    }
}

.content-object-heading {
    text-align: left;
    font-size: 38px;
}

.content-object-sub-heading {
    font-size: 22px;
    color: black;
}

.cont-horiz-white-card {
    height: 241px;
    width: auto;
    border-radius: 23px;
    background-color: #FFFFFF !important;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.19);
    justify-content: center;
    align-items: center;
}

.form-small-short {
    width: 1110px;
}

.form-header {
    font-size: 2rem;
    font-family: Poppins-Regular;
    text-align: center
}

.RQ-header {
    line-height: 52px;
    color: $RQ-Header;
    font-family: "Poppins-Regular";
    font-size: 30px;
    text-align: center;
}

.RQ-form-pos {
    padding: 0% 8% 0px 11%;
}


.loader-section {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner {
    width: 100px;
    height: 100px;
}

.spinner-sm {
    width: 40px;
    height: 40px;
}

@media only screen and (max-width:800px) {
    .login-object-mobile {
        padding: 0px 10%;
    }
}

.button-group-container {
    display: flex;
    justify-content: center;
    width: 1400px;

    @media only screen and (max-width:900px) {
        display: block;
        width: auto;
    }
}

.quotation-background-image {
    background-image: url(https://cdn.lonestarmga.com/img/quotation_141x121.svg);
    background-position: right top;
    background-repeat: no-repeat;

    @media only screen and (min-width: 850px)and (max-width:1000px) {
        background-position: right 65%;
    }

    @media only screen and (max-width:800px) {
        background-position: right 45% !important;
    }
}

.main-banner-text {
    max-width: 20%;
    font-family: "Poppins-Bold";
    font-size: 1.25rem;

    @media only screen and (max-width:800px) {
        font-size: 1rem;
        max-width: auto;
    }
}

.pa-btn-text-pos {
    padding-top: 1.5rem;
    padding-bottom: 2.8rem;
    text-align: center;

    @media only screen and (max-width:650px) {
        padding-top: 1.5rem;
        text-align: center;
        padding-bottom: .25rem;
    }
}

.pa-banner-btn-section-mobile {
    max-height: 244px;
    width: 360px;
    background-color: #FFFFFF;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.23);
    border-radius: 56px;
}

.pa-banner-btn-section {
    height: 136px;
    max-width: 933px;
    background-color: #FFFFFF;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.23);
    border-radius: 56px;
    padding: 0px 5%;

    @media only screen and (max-width:1000px) {
        gap: 10px;
        max-width: 733px;
    }

    @media only screen and (max-width:800px) {
        max-height: 136px;
        max-width: 933px;
        background-color: #FFFFFF;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.23);
        border-radius: 56px;
        padding: 0px 5%;
    }
}

.media-obj-content {
    img {
        height: 156px;
        max-width: 243px;
        margin-top: 6px;
        padding-bottom: 25px;

        @media only screen and (max-width:650px) {
            max-width: 150px;
            height: 120px;
        }
    }
}

.white-body-accordion {
    max-width: 930px;
    height: 100%;
    background-color: #FFFFFF;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.23);
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    display: inline-block;
    overflow-wrap: break-word;
    flex-wrap: wrap;
    padding-top: 2%;
    margin: 2% 5%;
}

.corner-wrapper {
    display: block;
    overflow: hidden;
    width: 550px;
    height: 350px;
    border-radius: 10px;
    transform: translateZ(0px);
    border: 3px solid #eee;

    iframe {
        width: 550px;
        height: 350px;

        @media only screen and (max-width:650px) {
            width: 250px;
            height: 150px;
        }
    }

    @media only screen and (max-width:650px) {
        width: 250px;
        height: 150px;
        flex-wrap: wrap;
    }
}

.xsmall-text {
    font-family: 'Poppins-Regular';
    font-size: .75rem;
    line-height: 1.125rem;
    color: #000;
}

.default-text {
    font-family: 'Poppins-Regular';
    font-size: 1rem;
    color: #000;
}

.regular-text {
    font-family: 'Poppins-Regular';
    font-size: 1.25rem;
    color: #000;
}

.md-text {
    font-family: 'Poppins-Regular';
    font-size: 1.5rem;
    color: #000;
}

.white-body-2 {
    padding: 20px 80px 20px 80px;

    @media only screen and (max-width:650px) {
        padding: 5px;
    }
}


.producer-portal-background {
    background: url(https://cdn.lonestarmga.com/img/ProducerLogin-bg.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
}

.producer-portal-size {
    max-width: 1920px;
    min-height: 750px;

    @media only screen and (max-width:1100px) {
        max-width: 100%;
        min-height: 400px;
    }
}

@media only screen and (max-width:900px) {
    .list-object-login-page {
        padding: 0px 5%;
        max-width: 100%;
    }
}


.business-hours {
    color: #565656;
    font-family: Poppins;
    font-size: 15px;
    letter-spacing: 0;
}

.simple-headline {
    color: #222222;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 30px;
}

.divider {
    box-sizing: border-box;
    height: 1px;
    width: 100%;
    border: 1px solid #979797;
}


@media only screen and (max-width:800px) {
    .flex-wrap-mobile {
        flex-wrap: wrap;
    }

    .mobile-width-350 {
        min-width: 350px;
        display: grid;
        justify-content: center;

        @media only screen and (max-width:450px) {
            max-width: 400px;
            display: grid;
            justify-content: center;
        }
    }
}

.heading-large {
    font-family: 'Poppins-Regular';
    font-size: 2rem;
    line-height: 2.8125rem;
    color: black;

    @media only screen and (max-width:550px) {
        font-size: 1.25rem;
    }
}

.sub-heading-red {
    font-family: Poppins-Semi-Bold;
    font-size: 1.5rem;
    color: #B22727;

    @media only screen and (max-width:550px) {
        font-size: 1rem;
    }
}

.col-sec-bottom-image {
    max-width: 970px;

    @media only screen and (max-width:800px) {
        max-width: 100%;
        padding: 0px 20px;
    }
}

.error-payment-form {
    font-size: 1rem;
    font-family: Poppins-Bold;
    text-align: center;
    color: #B22727;
}

.about-us-body-text {
    padding-top: 100px;
    justify-content: center;
    max-width: 1100px;

    @media only screen and (max-width:800px) {
        padding-top: 150px;
    }
}

.about-us-core-features {
    padding: 2% 0px;

    @media only screen and (max-width:800px) {
        justify-content: center;
        align-items: center;
        padding-top: 15% !important;
        padding-bottom: 20% !important;
    }
}

.about-us-our-team {
    justify-content: center;
    margin-bottom: -50px;
}

.product-banner {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding-top: 1rem;

    @media only screen and (max-width:800px) {
        padding: 3% 0px;
    }
}

.product-banner-icon {
    width: 64px;
    height: 64px;

    @media only screen and (max-width:800px) {
        max-width: 44px;
        max-height: 44px;
    }
}

.mobile-p-2 {
    @media only screen and (max-width:800px) {
        padding: 2rem;
    }
}

.form-guide-page-body {
    min-height: 800px;
    background-color: #f0f0f0;
    padding-bottom: 40px;
}

.form-guide-product-header {
    font-size: 30px;
    text-align: center;
}

.form-guide-PTS-logo {
    max-width: 250px;
    max-height: 200px;
}

.document-publication-page-body {
    background-color: #F0F0F0;
    min-height: 450px;
}

.compensation-page-body {
    background-color: #F0F0F0;
    min-height: 400px;
}

.announcement-page-body {
    min-height: 800px;
    background-color: #f0f0f0;
    padding-bottom: 45px;

    .crumb-container {
        width: 1230px;
    }
}

.change-password-page-body {
    padding-bottom: 5%;
    background-color: #f0f0f0;
}

.white-blue-border-box {
    min-height: 61px;
    border: 2px solid #004C78;
    box-sizing: border-box;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    background-color: white !important;

    @media only screen and (max-width:800px) {
        margin-bottom: 5%;
        width: 100%;
    }

    .text {
        width: auto;
        padding: 20px;
        min-height: 35px;
        color: #004C78;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 20px;
        text-align: center;

        @media only screen and (max-width:800px) {
            font-size: 1rem !important;
            line-height: 25px !important;
            padding: 2.5%;
            text-align: left;
        }
    }
}
.white-red-border-box {
    min-height: 61px;
    border: 2px solid #B22727;
    box-sizing: border-box;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    background-color: white !important;

    @media only screen and (max-width:800px) {
        margin-bottom: 5%;
        width: 100%;
    }

    .text {
        width: auto;
        padding: 20px;
        min-height: 35px;
        color: #004C78;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 20px;
        text-align: center;

        @media only screen and (max-width:800px) {
            font-size: 1rem !important;
            line-height: 25px !important;
            padding: 2.5%;
            text-align: left;
        }
    }
}
.contact-inquiry-buttons {
    gap: 30px;

    @media only screen and (max-width:800px) {
        gap: 5px;
    }
}

.contact-list-body {
    display: grid;

    @media only screen and (max-width:800px) {
        width: auto;
        display: grid;
    }
}

.content-list-item {
    text-align: center;

    @media only screen and (max-width:800px) {
        padding: 2% 0px;
    }
}

.content-list-text-item {
    text-align: start;

    @media only screen and (max-width:800px) {
        padding: 2% 0px;
        text-align: center;
    }
}

.content-list-text-item-accordion {
    text-align: center;
}

.content-list-main-title {
    color: #222222;
    font-family: Poppins-Semi-Bold;
    font-size: 28px;
    letter-spacing: 0;
}

.content-list-main-icon {
    max-width: 37px;
    max-height: 37px;
}

.content-list-icon {
    max-width: 40px;
    max-height: 40px;
}

.content-list-text {
    color: #222222;
    font-family: Poppins-Semi-Bold;
    font-size: 18px;
    letter-spacing: 0;
    text-align: center;
}

.content-list-text-title {
    color: #222222;
    font-family: Poppins-Semi-Bold;
    font-size: 20px;
    letter-spacing: 0;
    text-align: center;
}

.content-list-item-body {
    display: flex;
    justify-content: start;
    padding: 3% 2%;
    border-radius: 36px;
    background-color: #FFFFFF;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.23);

    @media only screen and (max-width:800px) {
        display: flow-root;
    }
}

.content-list-item-title {
    font-family: Poppins-Semi-Bold;
    font-size: 16px;
}

.content-list-item-subtitle {
    font-family: Poppins-Semi-Bold;
    font-size: 16px;
    color: #B22727;
}

.page-start-contact-us {
    color: #222222;
    font-family: "Poppins-Regular";
    font-size: 19.5px;
    padding: 0% 0% 0% 0%;
    flex-wrap: wrap;
    width: 1110px;
}

.px-12 {
    font-size: 12px;
}

.px-16 {
    font-size: 16px;
}

.px-18 {
    font-size: 18px;
}

.px-20 {
    font-size: 20px;
}

.contact-us-form-body {
    padding: 5% 5%;
}

.contact-page-body {
    display: flex;
    justify-content: center;
    min-height: 1200px;
    background-color: #F0F0F0;
}

.personal-auto-mo-section {
    max-width: 1320px;
    @media only screen and (max-width: 1281px) {
        max-width: 1100px;
    }

    @media only screen and (max-width: 800px) {
        width: 100%;
    }
}

 img .login-object-image:hover{
     background-color:transparent !important;
 }